import React, { useState } from 'react';

import { JSBI } from 'sdk';
import { Modal } from 'react-bootstrap';

import btc from '../../assets/images/btc.png';
import btcMn from '../../assets/images/btcMn.png';
import SingleStakingModal from 'components/earn/SingleStakingModal';
import SingleUnstakingModal from 'components/earn/SingleUnstakingModal';

const Withdrawmodals = ({ stakingInfo, currency, userLiquidityUnstaked, showAddLiquidityButton }) => {
    const [deposit, setDeposit] = useState(false);
    const [Recieve, setRecieve] = useState(false);
    const [depost, setDepost] = useState(false);
    const [trans, setTrans] = useState(false);
    const [unStake, setUnStake] = useState(false);
    const Depositopen = () => {
        setDeposit(true);
    }
    const DepositCancel = () => {
        setDeposit(false);
    }
    const Claimwithdraw = () => {
        setDeposit(false)
    }
    const Approvfnc = () => {
        setTrans(true);
        setRecieve(false);
    }
    const Depostfnc = () => {
        setRecieve(false);
    }

    return (
        <>
            {!showAddLiquidityButton && (
                <div>
                    <div className='d-flex'>
                        {stakingInfo && stakingInfo.active && (
                            <button className='btnMain x-large-btn boxShdwNone' onClick={Depositopen}>
                                {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) ? 'Deposit' : `Deposit ${currency?.symbol}`}
                            </button>
                        )}

                        {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) && (
                            <button className='btnMain bglightBlue boxShdwNone x-large-btn' onClick={() => setUnStake(true)}>Withdraw</button>
                        )}
                    </div>
                    {stakingInfo &&
                        <>
                            <SingleStakingModal
                                isOpen={deposit}
                                onDismiss={DepositCancel}
                                stakingInfo={stakingInfo}
                                userLiquidityUnstaked={userLiquidityUnstaked}
                            >
                            </SingleStakingModal>
                            <SingleUnstakingModal
                                isOpen={unStake}
                                onDismiss={() => setUnStake(false)}
                                stakingInfo={stakingInfo}
                            />
                        </>
                    }

                </div>
            )}
        </>
    );
};

export default Withdrawmodals;