import React from 'react'
import styled from 'styled-components'
import { DropdownButton, StyledMenu, StyledMenuText } from './styled';
import { BridgeChain } from 'constants/index'
import { ChevronDown } from 'react-feather'
import MenuItem from '@mui/material/MenuItem'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import ethereumlogo from '../../assets/images/ethereum-logo.png'
import binancelogo from '../../assets/images/binance-logo.png'
import hecologo from '../../assets/images/heco-logo.png'

const chains: {
  [chainId in BridgeChain]: {
    img: string,
    name: string
  }
} = {
  [BridgeChain.ETH]: {
    img: ethereumlogo,
    name: 'ETH'
  },
  [BridgeChain.BSC]: {
    img: binancelogo,
    name: 'BSC'
  },
  [BridgeChain.HECO]: {
    img: hecologo,
    name: 'HECO'
  }
};

const supportedChainIds: Array<BridgeChain> = [
  BridgeChain.ETH,
  BridgeChain.BSC,
  BridgeChain.HECO
]

const Wrapper = styled.div`
  background: ${({ theme }) => theme.bg9};
  width: 100%;
  padding: 16px;
  margin: 24px 0px;
  display: flex;
  align-items: center;
  border-radius: 60px;
  
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 8px;
  `};

`

const ChainLogo = styled.div`

  margin: 0px 12px;

  img {
    width: 32px;
  
    ${({ theme }) => theme.mediaWidth.upToSmall`
      width: 24px;
    `};
  }

`

const ChainText = styled.div`
  flex: 1;

`

export default function ChainSelector({
  chainId,
  onChange
}: {
  chainId: BridgeChain,
  onChange: (oldChain: BridgeChain, newChain: BridgeChain) => void
}) {

  const selectChain = (_chainId: BridgeChain) => {
    onChange(chainId, _chainId);
  }

  return (
    <Wrapper>

      <ChainLogo>
        <img src={chains[chainId].img} alt="Chain Logo" />
      </ChainLogo>

      <ChainText>
        {chains[chainId].name}
      </ChainText>

      <PopupState variant="popover">
        {(popupState) => (
          <>
            <DropdownButton
              {...bindTrigger(popupState)}
            >
              <ChevronDown size={24} />
            </DropdownButton>
            <StyledMenu {...bindMenu(popupState)}>
              {
                supportedChainIds.map((_chainId, idx) =>
                  <MenuItem onClick={() => { popupState.close(); selectChain(_chainId); }} key={idx}>
                    <img src={chains[_chainId].img} alt={chains[_chainId].name + " Logo"} width={24} />
                    <StyledMenuText> {chains[_chainId].name} </StyledMenuText>
                  </MenuItem>
                )
              }
            </StyledMenu>
          </>
        )}
      </PopupState>

    </Wrapper>
  )

}
