import { Input as NumericalInput } from '../NumericalInput'
import { CurrencyAmount, Rounding } from 'sdk'
import { ButtonPrimary } from 'components/Button'
import React from 'react'
import styled from 'styled-components'
import { Text } from 'rebass'

const Wrapper = styled.div`
  background: ${({ theme }) => theme.bg9};
  width: 100%;
  padding: 12px 24px;
  margin: 0px;
  border-radius: 60px;
  font-size: 20px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};

`

const InputWrapper = styled.div`
  display: flex;
`

const AmountInput = styled(NumericalInput)`
  width: 100%;
  background: transparent;
  font-size: 18px;

  & input {
    font-family: "Inter var", sans-serif;
  }

`
const ButtonMax = styled(ButtonPrimary)`
  width: auto;
  filter: drop-shadow(0px 12px 25px rgba(28, 64, 194, 0.24));
  margin: 0px 0px 0px 24px;
  font-size: 14px;
  padding: 4px 8px;
  border-radius: 4px;

`

const BalanceText = styled(Text)`
  text-align: right;
  font-size: 14px;
  margin-bottom: 8px !important;
`

export default function AmountSelector({
  amount,
  balance,
  onChange
}: {
  amount: string,
  balance: CurrencyAmount | undefined,
  onChange: (amount_: string) => void
}) {

  const maxBalance = () => {
    onChange(balance?.toExact() ?? '');
  }

  return (
    <Wrapper style={{ marginTop: '24px' }}>

      <BalanceText>
        Balance: {balance ? balance.toExact() : '-'}
      </BalanceText>

      <InputWrapper>
        <AmountInput type="number" style={{ color: 'white' }} value={amount}
          onUserInput={val => { onChange(val) }} placeholder="Enter token amount" />
        <ButtonMax onClick={maxBalance}>
          MAX
        </ButtonMax>
      </InputWrapper>

    </Wrapper>
  )

}
