import { Contract } from '@ethersproject/contracts'
import { abi as GOVERNANCE_ABI } from '@uniswap/governance/build/GovernorAlpha.json'
import { abi as UNI_ABI } from '@uniswap/governance/build/Uni.json'
import { abi as STAKING_REWARDS_ABI } from '@uniswap/liquidity-staker/build/StakingRewards.json'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { GOVERNANCE_TOKEN_ABI } from 'constants/abis/governance-token'
import { useEffect, useMemo, useState } from 'react'
import {
  AUSDC_ADDRESS,
  AWETH_ADDRESS,
  BridgeToken,
  GOVERNANCE_ADDRESS,
  HOKK,
  MASTER_BREEDER,
  STAKING_MASTER_BREEDER,
  ZERO_ADDRESS
} from '../constants'
import {
  ARGENT_WALLET_DETECTOR_ABI,
  ARGENT_WALLET_DETECTOR_MAINNET_ADDRESS
} from '../constants/abis/argent-wallet-detector'
import ENS_PUBLIC_RESOLVER_ABI from '../constants/abis/ens-public-resolver.json'
import ENS_ABI from '../constants/abis/ens-registrar.json'
import ERC20_INTERFACE, { ERC20_BYTES32_ABI } from '../constants/abis/erc20'
import ERC20_ABI from '../constants/abis/erc20.json'
import { MIGRATOR_ABI, MIGRATOR_ADDRESS } from '../constants/abis/migrator'
import { abi as MASTER_BREEDER_ABI } from '../constants/abis/master-breeder.json'
import { abi as STAKING_MASTER_BREEDER_ABI } from '../constants/abis/staking-master-breeder.json'
import WETH_ABI from '../constants/abis/weth.json'
import { MULTICALL_ABI, MULTICALL_NETWORKS } from '../constants/multicall'
import { getContract, tryParseEth, tryParseUsdc } from '../utils'
import { useWeb3React } from '@web3-react/core'
import useGovernanceToken from './useGovernanceToken'
import { WETH, ChainId, CurrencyAmount } from 'sdk'
import {
  useMultipleContractSingleData,
  useSingleCallResult,
  useSingleContractMultipleData
} from 'state/multicall/hooks'
import { tryParseAmount } from 'state/swap/hooks'
import { BigNumber } from 'ethers'
import axios from 'axios'
import { HOKK_PREMIUM_ABI, HOKK_PREMIUM_ADDRESS } from 'constants/abis/hokkpremium'
import { HOKK_NFT_ABI, HOKK_NFT_ADDRESS } from 'constants/abis/hokknft'

// returns null on errors
function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { provider, account } = useWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !provider) return null
    try {
      return getContract(address, ABI, provider, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, provider, withSignerIfPossible, account])
}

export function useV2MigratorContract(): Contract | null {
  return useContract(MIGRATOR_ADDRESS, MIGRATOR_ABI, true)
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWETHContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(chainId ? WETH[chainId]?.address : undefined, WETH_ABI, withSignerIfPossible)
}

export function useArgentWalletDetectorContract(): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(
    chainId === ChainId.MAINNET ? ARGENT_WALLET_DETECTOR_MAINNET_ADDRESS : undefined,
    ARGENT_WALLET_DETECTOR_ABI,
    false
  )
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3React()
  let address: string | undefined
  if (chainId) {
    switch (chainId) {
      case ChainId.MAINNET:
      case ChainId.GÖRLI:
        address = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
        break
    }
  }
  return useContract(address, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(pairAddress, IUniswapV2PairABI, withSignerIfPossible)
}

export function useMulticallContract(): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(chainId ? MULTICALL_NETWORKS[chainId] : undefined, MULTICALL_ABI, false)
}

export function useGovernanceContract(): Contract | null {
  return useContract(GOVERNANCE_ADDRESS, GOVERNANCE_ABI, true)
}

export function useUniContract(): Contract | null {
  return useContract(useGovernanceToken()?.address, UNI_ABI, true)
}

export function useGovTokenContract(): Contract | null {
  return useContract(useGovernanceToken()?.address, GOVERNANCE_TOKEN_ABI, true)
}

export function useStakingContract(stakingAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(stakingAddress, STAKING_REWARDS_ABI, withSignerIfPossible)
}

export function useMasterBreederContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3React()
  const address = chainId ? MASTER_BREEDER[chainId] : undefined
  return useContract(address, MASTER_BREEDER_ABI, withSignerIfPossible)
}

export function useStakingMasterBreederContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3React()
  const address = chainId ? STAKING_MASTER_BREEDER[chainId] : undefined
  return useContract(address, STAKING_MASTER_BREEDER_ABI, withSignerIfPossible)
}

export function usePremiumContract(): Contract | null {
  return useContract(HOKK_PREMIUM_ADDRESS, HOKK_PREMIUM_ABI, false)
}

export function useNFTContract(): Contract | null {
  return useContract(HOKK_NFT_ADDRESS, HOKK_NFT_ABI, false)
}

export function useETHPrice(): number {
  const [ethPrice, setEthPrice] = useState<number>(0)

  useEffect(() => {
    async function fetchEthPrice() {
      const { data } = await axios.get('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD')
      if (data.USD) {
        setEthPrice(data.USD)
      }
    }

    fetchEthPrice()
  }, [])

  return ethPrice
}

export function useAaveAPY(): {
  ethAPY: number | undefined
  usdcAPY: number | undefined
} {
  const [usdcAPY, setUsdcAPY] = useState<number>()
  const [ethAPY, setEthAPY] = useState<number>()

  useEffect(() => {
    async function fetchAPY() {
      const { data } = await axios.get('https://aave-api-v2.aave.com/data/markets-data')
      if (data.reserves) {
        const reserves: Array<any> = data.reserves

        const wethRates = reserves
          .filter(r => r.aTokenAddress.toLowerCase() === AWETH_ADDRESS.toLowerCase())
          .map(r => r.liquidityRate)
        if (wethRates.length > 0) {
          setEthAPY(wethRates[0])
        }

        const usdcRates = reserves
          .filter(r => r.aTokenAddress.toLowerCase() === AUSDC_ADDRESS.toLowerCase())
          .map(r => r.liquidityRate)
        if (usdcRates.length > 0) {
          setUsdcAPY(usdcRates[0])
        }
      }
    }

    fetchAPY()
  }, [])

  return {
    ethAPY: ethAPY,
    usdcAPY: usdcAPY
  }
}

export function useCurrentAPY(isEth: boolean): BigNumber {
  const contract = usePremiumContract()
  const method = isEth ? 'getCurrentETHAPY' : 'getCurrentUSDCAPY'
  const amountResult = useSingleCallResult(contract, method)
  return amountResult.result?.[0]
}

export function useUserData(
  account: string | null | undefined
): {
  userEthDeposit: BigNumber
  userUsdcDeposit: BigNumber
  userEthLockup: BigNumber
  userUsdcLockup: BigNumber
  userEthYield: BigNumber
  userUsdcYield: BigNumber
  userNftIds: Array<string>
} {
  const contract = usePremiumContract()

  const { result } = useSingleCallResult(contract, 'getUserData', [account ?? undefined])

  let _userNftIds = result?.[6] ?? []

  return {
    userEthDeposit: result?.[0] ?? undefined,
    userUsdcDeposit: result?.[1] ?? undefined,
    userEthLockup: result?.[2] ?? undefined,
    userUsdcLockup: result?.[3] ?? undefined,
    userEthYield: result?.[4] ?? undefined,
    userUsdcYield: result?.[5] ?? undefined,
    userNftIds: _userNftIds
  }
}

export function useUserNFTs(account: string | null | undefined): Array<string> {
  const contract = usePremiumContract()

  const { result } = useSingleCallResult(contract, 'getUserNFTIDs', [account ?? undefined])

  return result?.[0] ?? undefined
}

export function useNftOwner(tokenId: string | undefined): string | undefined {
  const contract = useNFTContract()

  const { result } = useSingleCallResult(contract, 'ownerOf', [tokenId])
  return result?.[0] ?? undefined
}

export function useNftYield(isEth: boolean, tokenId: string | undefined): BigNumber | undefined {
  const contract = usePremiumContract()

  const yieldFunction = isEth ? 'getNFTETHYield' : 'getNFTUSDCYield'
  const { result } = useSingleCallResult(contract, yieldFunction, [tokenId])
  return result?.[0] ?? undefined
}

export function useNftYields(isEth: boolean, tokenIds: Array<string>): { [tokenId: string]: BigNumber | undefined } {
  const contract = usePremiumContract()

  const yieldFunction = isEth ? 'getNFTETHYield' : 'getNFTUSDCYield'

  const results = useSingleContractMultipleData(
    contract,
    yieldFunction,
    tokenIds.map(tokenId => [tokenId.toString()])
  )

  return useMemo(
    () =>
      tokenIds.reduce<{ [tokenId: string]: BigNumber }>((memo, tokenId, i) => {
        const value = results?.[i]?.result?.[0]
        if (value) memo[tokenId] = value
        return memo
      }, {}),
    [tokenIds, results]
  )
}

export function useTotalETHDeposit(): BigNumber | undefined {
  const contract = usePremiumContract()

  const result = useSingleCallResult(contract, 'totalETHDeposit', []).result
  return result?.[0] ?? undefined
}

export function useTotalUSDCDeposit(): BigNumber | undefined {
  const contract = usePremiumContract()

  const result = useSingleCallResult(contract, 'totalUSDCDeposit', []).result
  return result?.[0] ?? undefined
}

export function useLockedAmount(
  ethAmount: BigNumber | undefined,
  usdcAmount: BigNumber | undefined,
  ethPrice: number
): number | undefined {
  if (ethAmount && usdcAmount) {
    const ethValue = parseFloat(tryParseEth(ethAmount) ?? '0') * ethPrice
    const usdcValue = parseFloat(tryParseUsdc(usdcAmount) ?? '0')
    return ethValue + usdcValue
  } else {
    return undefined
  }
}

export function useYieldAmount(
  ethAmount: BigNumber | undefined,
  usdcAmount: BigNumber | undefined,
  ethPrice: number
): number | undefined {
  const balances = useMultipleContractSingleData([AWETH_ADDRESS, AUSDC_ADDRESS], ERC20_INTERFACE, 'balanceOf', [
    HOKK_PREMIUM_ADDRESS
  ])

  const awethAmount: BigNumber = balances?.[0].result?.[0] ?? BigNumber.from(0)
  const ausdcAmount: BigNumber = balances?.[1].result?.[0] ?? BigNumber.from(0)

  if (ethAmount && usdcAmount) {
    const ethValue = parseFloat(tryParseEth(awethAmount.sub(ethAmount)) ?? '0') * ethPrice
    const usdcValue = parseFloat(tryParseUsdc(ausdcAmount.sub(usdcAmount)) ?? '0')
    return ethValue + usdcValue
  } else {
    return undefined
  }
}
