import React from 'react'
import styled from 'styled-components'
import { RowBetween } from '../Row'
import { TYPE } from '../../theme'
import SettingsButton from 'components/Settings/SettingsButton'
import SettingsTab from '../Settings'

const StyledSwapHeader = styled.div`
  padding: 1rem 1rem 0px 1rem;
  margin-bottom: -4px;
  width: 100%;
  color: ${({ theme }) => theme.text2};
`

export default function SwapHeader() {
  return (
    <StyledSwapHeader>
      <RowBetween>
        <TYPE.black fontWeight={500}>Swap</TYPE.black>
        <SettingsButton />
      </RowBetween>

      <SettingsTab />

    </StyledSwapHeader>
  )
}
