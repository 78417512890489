import { Token, TokenAmount, Fraction, Price } from 'sdk'
import calculateTotalSingleStakedAmount from './calculateTotalSingleStakedAmount'

function pairCurrencyAmountInWeth(
  baseToken: Token | undefined,
  tokenPrice: Price | undefined,
  valueOfTotalStakedAmountInPairCurrency: TokenAmount
): TokenAmount | Fraction | undefined {
  if (!baseToken || !tokenPrice) return valueOfTotalStakedAmountInPairCurrency

  return valueOfTotalStakedAmountInPairCurrency.multiply(tokenPrice.raw)
}

export default function calculateWethAdjustedTotalSingleStakedAmount(
  baseToken: Token | undefined,
  tokenData: Price | undefined,
  totalStakedAmount: TokenAmount,
): TokenAmount | Fraction | undefined {
  if (!baseToken) return undefined

  const valueOfTotalStakedAmountInPairCurrency = calculateTotalSingleStakedAmount(
    baseToken,
    totalStakedAmount,
  )
  if (!valueOfTotalStakedAmountInPairCurrency) return undefined

  return pairCurrencyAmountInWeth(baseToken, tokenData, valueOfTotalStakedAmountInPairCurrency)
}
