import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { ApplicationModal } from '../../state/application/actions'
import { ReactComponent as Close } from '../../assets/images/x.svg'
import { useModalOpen, useToggleModal } from '../../state/application/hooks'
import { useWeb3React } from '@web3-react/core'

import Modal from '../Modal'
import { ButtonPrimary } from 'components/Button';
import { getTokenImage, getTokenName, getTokenAmount, getPremiumContract, calculateGasMargin } from 'utils';
import { Input as NumericalInput } from 'components/NumericalInput';
import { useNftOwner, useNftYield } from 'hooks/useContract'
import { useUserAsset } from 'state/user/hooks'
import { useIsTransactionPending, useTransactionAdder } from 'state/transactions/hooks'
import { TransactionResponse } from '@ethersproject/abstract-provider'
import { BigNumber } from 'ethers'
import { TYPE } from 'theme'

const Wrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 0;
  width: 100%;
`

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.text4};
  }
`

const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  padding: 1rem 1rem;
  font-weight: 500;
  color: ${props => (props.color === 'blue' ? ({ theme }) => theme.primary1 : 'inherit')};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
  `};
`

const UpperSection = styled.div`
  position: relative;

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`

const InfoCard = styled.div`
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.bg5};
  border-radius: 20px;
  position: relative;
  display: grid;
  grid-row-gap: 12px;
`

const AccountSection = styled.div`
  padding: 0rem 1rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`padding: 0rem 1rem 1.5rem 1rem;`};
`

const NftItem = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 18px 0px;
`

const NftImg = styled.img`
  width: 100px;
  height: 100px;
`

const TitleWrapper = styled.div`
  flex: 1;
`

const NftTitle = styled.div`
  padding-bottom: 8px;
`

const NftSubtitle = styled.div`
  font-size: 14px;
`

const NftEmpty = styled.div`
  text-align: center;
  margin: 32px 0px;
`

const ButtonClaim = styled(ButtonPrimary)`
  margin-top: 12px;
  width: 100px;
  height: 36px;
  border-radius: 12px;
`

const StyledInput = styled(NumericalInput)`
  width: 100%;
  background: transparent;
`


export default function NftSearchModal() {

  const searchModalOpen = useModalOpen(ApplicationModal.NFT_SEARCH)
  const toggleSearchModal = useToggleModal(ApplicationModal.NFT_SEARCH);

  const { chainId, account, provider } = useWeb3React();
  const addTransaction = useTransactionAdder();
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const isPending = useIsTransactionPending(txHash);

  const [isEth] = useUserAsset()
  const coinName = isEth ? 'ETH' : 'USDC';
  const [tokenId, setTokenId] = useState<string>();
  const tokenOwner = useNftOwner(tokenId);
  const yieldAmount = useNftYield(isEth, tokenId);
  const isOwner = tokenOwner === account;

  const onUserInput = useCallback((typedValue: string) => {
    if (typedValue) {
      setTokenId(typedValue);
    }
    else {
      setTokenId(undefined);
    }
  }, [])

  const handleClaimNFT = () => {
    if (tokenId) {
      claimNFT(tokenId);
    }
  }

  async function claimNFT(tokenId: string) {
    if (!chainId || !provider || !account) return
    const contract = getPremiumContract(chainId, provider, account)

    let estimate,
      method: (...args: any) => Promise<TransactionResponse>,
      args: Array<string | string[] | number>,
      value: BigNumber | null;

    estimate = isEth ? contract.estimateGas.claimNFTETHYield : contract.estimateGas.claimNFTUSDCYield
    method = isEth ? contract.claimNFTETHYield : contract.claimNFTUSDCYield
    args = [
      [
        tokenId
      ]
    ]

    setAttemptingTxn(true)
    await estimate(...args, {})
      .then(estimatedGasLimit =>
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit)
        }).then(response => {
          setAttemptingTxn(false)

          addTransaction(response, {
            summary:
              'Claim has been successed.'
          })
          setTxHash(response.hash)
          setErrorMessage('');
          toggleSearchModal();
        })
      )
      .catch(error => {
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          if (error.data) {
            setErrorMessage(error.data.message);
          }
          else {
            setErrorMessage(error.message);
          }
        }
        else {
          setErrorMessage('User rejected transaction.');
        }
      })
  }

  return (
    <Modal isOpen={searchModalOpen} onDismiss={toggleSearchModal} minHeight={false} maxHeight={90}>
      <Wrapper>
        <UpperSection>
          <CloseIcon onClick={toggleSearchModal}>
            <CloseColor />
          </CloseIcon>
          <HeaderRow>Search NFT</HeaderRow>
          <AccountSection>

            <InfoCard>
              <StyledInput value={tokenId ?? ""} onUserInput={onUserInput} placeholder='Input NFT #Id' />
            </InfoCard>

            {
              tokenOwner ?
                <NftItem>
                  <NftImg src={getTokenImage(tokenId)} />
                  <TitleWrapper>
                    <NftTitle>{getTokenName(tokenId)}</NftTitle>
                    <NftSubtitle>{getTokenAmount(isEth, yieldAmount)} {coinName}</NftSubtitle>
                    <ButtonClaim onClick={handleClaimNFT} disabled={isPending || attemptingTxn || !isOwner}>Claim</ButtonClaim>
                    {
                      errorMessage ? <TYPE.error error={true} marginLeft={'12px;'}>
                        {errorMessage}
                      </TYPE.error> : null
                    }
                  </TitleWrapper>
                </NftItem>
                : <NftEmpty>
                  --- No Items ---
                </NftEmpty>
            }

          </AccountSection>
        </UpperSection>
      </Wrapper>
    </Modal>
  )
}
