import React, { useState, useCallback } from 'react'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { AutoColumn } from '../Column'
import styled from 'styled-components'
import { RowBetween } from '../Row'
import { TYPE, CloseIcon } from '../../theme'
import { ButtonConfirmed, ButtonError } from '../Button'
import ProgressCircles from '../ProgressSteps'
import CurrencyInputPanel from '../CurrencyInputPanel'
import { TokenAmount } from 'sdk'
import { useWeb3React } from '@web3-react/core'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { useApproveCallback, ApprovalState } from '../../hooks/useApproveCallback'
import { useDerivedStakeInfo, SingleStakingInfo } from '../../state/stake/hooks'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { LoadingView, SubmittedView } from '../ModalViews'
import { useMasterBreederContract } from '../../hooks/useContract'
import { BlueCard } from '../Card'
import { ColumnCenter } from '../Column'
import { calculateGasMargin } from '../../utils'

import { Modal as BootStrapModal } from 'react-bootstrap';

/*const HypotheticalRewardRate = styled.div<{ dim: boolean }>`
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;

  opacity: ${({ dim }) => (dim ? 0.5 : 1)};
`*/

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

interface StakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  stakingInfo: SingleStakingInfo
  userLiquidityUnstaked: TokenAmount | undefined
}

export default function SingleStakingModal({ isOpen, onDismiss, stakingInfo, userLiquidityUnstaked }: StakingModalProps) {
  const { provider } = useWeb3React()

  // track and parse user input
  const [typedValue, setTypedValue] = useState('')
  const { parsedAmount, error } = useDerivedStakeInfo(typedValue, stakingInfo?.stakedAmount.token, userLiquidityUnstaked)
  /*const parsedAmountWrapped = wrappedCurrencyAmount(parsedAmount, chainId)

  let hypotheticalRewardRate: TokenAmount = new TokenAmount(stakingInfo.rewardRate.token, '0')
  if (parsedAmountWrapped?.greaterThan('0')) {
    hypotheticalRewardRate = stakingInfo.getHypotheticalRewardRate(
      stakingInfo.stakedAmount.add(parsedAmountWrapped),
      stakingInfo.totalStakedAmount.add(parsedAmountWrapped),
      stakingInfo.totalRewardRate
    )
  }*/

  // state for pending and submitted txn views
  const addTransaction = useTransactionAdder()
  const [attempting, setAttempting] = useState<boolean>(false)
  const [hash, setHash] = useState<string | undefined>()
  const [failed, setFailed] = useState<boolean>(false)
  const wrappedOnDismiss = useCallback(() => {
    setHash(undefined)
    setAttempting(false)
    setFailed(false)
    onDismiss()
  }, [onDismiss])

  const masterBreeder = useMasterBreederContract()
  const referral = "0x674aC2fA134B37E38f964053Ca5D510819de3E3c"

  // approval data for stake
  const deadline = useTransactionDeadline()
  const [signatureData, setSignatureData] = useState<{ v: number; r: string; s: string; deadline: number } | null>(null)
  const [approval, approveCallback] = useApproveCallback(parsedAmount, masterBreeder?.address)

  async function onStake() {
    setAttempting(true)
    if (masterBreeder && parsedAmount && deadline) {
      if (approval === ApprovalState.APPROVED) {
        const formattedAmount = `0x${parsedAmount.raw.toString(16)}`

        const estimatedGas = await masterBreeder.estimateGas.deposit(stakingInfo.pid, formattedAmount, referral)

        await masterBreeder
          .deposit(stakingInfo.pid, formattedAmount, referral, {
            gasLimit: calculateGasMargin(estimatedGas)
          })
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              summary: `Deposit liquidity`
            })
            setHash(response.hash)
          })
          .catch((error: any) => {
            setAttempting(false)
            if (error?.code === -32603) {
              setFailed(true)
            }
            console.log(error)
          })
      } else {
        setAttempting(false)
        throw new Error('Attempting to stake without approval or a signature. Please contact support.')
      }
    }
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback((typedValue: string) => {
    setSignatureData(null)
    setTypedValue(typedValue)
  }, [])

  // used for max input button
  const maxAmountInput = maxAmountSpend(userLiquidityUnstaked)
  const atMaxAmount = Boolean(maxAmountInput && parsedAmount?.equalTo(maxAmountInput))
  const handleMax = useCallback(() => {
    maxAmountInput && onUserInput(maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])

  async function onAttemptToApprove() {
    if (!provider || !deadline) throw new Error('missing dependencies')
    const liquidityAmount = parsedAmount
    if (!liquidityAmount) throw new Error('missing liquidity amount')

    return approveCallback()
  }

  return (
    <BootStrapModal
      show={isOpen}
      onClose={onDismiss}
      centered={true}
    >
      <div className='popupWrapper'>
        {!attempting && !hash && !failed && (
          <>
            <RowBetween>
              <div className='modalHeader'><h3>Deposit</h3></div>
              <CloseIcon onClick={wrappedOnDismiss} />
            </RowBetween>
            <div className='blueBgRound  mgBoth d-block'>Important : The deposit fee is now 0.75% !</div>

            <div className='modal_footer textCenter depositBx'>
              {stakingInfo != undefined &&
                <CurrencyInputPanel
                  value={typedValue}
                  onUserInput={onUserInput}
                  onMax={handleMax}
                  showMaxButton={!atMaxAmount}
                  currency={stakingInfo.stakedAmount.token}
                  label={''}
                  disableCurrencySelect={true}
                  customBalanceText={'Available to deposit: '}
                  id="stake-liquidity-token"
                />
              }
              <RowBetween margin="12px 0px">
                <ButtonConfirmed
                  className='btnMain boxShdwNone widthFull mr-5'
                  mr="0.5rem"
                  onClick={onAttemptToApprove}
                  confirmed={approval === ApprovalState.APPROVED || signatureData !== null}
                  disabled={approval !== ApprovalState.NOT_APPROVED || signatureData !== null}
                >
                  Approve
                </ButtonConfirmed>
                <ButtonError
                  className='btnMain widthFull boxShdwNone bgBlack'
                  disabled={!!error || (signatureData === null && approval !== ApprovalState.APPROVED)}
                  error={!!error && !!parsedAmount}
                  onClick={onStake}
                >
                  {error ?? 'Deposit'}
                </ButtonError>
              </RowBetween>

              <div className='d-flex align-item-center justifyCenter mt30'>
                <ProgressCircles steps={[approval === ApprovalState.APPROVED || signatureData !== null]} disabled={true} />
                {/* <ul className='cirlcNmber whtClr'>
                            <li><span>1</span></li>
                            <li><span>2</span></li>
                        </ul> */}
              </div>
            </div>
          </>
        )}
        {attempting && !hash && !failed && (
          <LoadingView onDismiss={wrappedOnDismiss}>
            <AutoColumn gap="12px" justify={'center'}>
              <TYPE.largeHeader>Depositing Liquidity</TYPE.largeHeader>
              <TYPE.body fontSize={20}>{parsedAmount?.toSignificant(4)} {stakingInfo?.stakedAmount.currency.symbol}</TYPE.body>
            </AutoColumn>
          </LoadingView>
        )}
        {attempting && hash && !failed && (
          <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
            <AutoColumn gap="12px" justify={'center'}>
              <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
              <TYPE.body fontSize={20}>Deposited {parsedAmount?.toSignificant(4)} {stakingInfo?.stakedAmount.currency.symbol}</TYPE.body>
            </AutoColumn>
          </SubmittedView>
        )}
        {!attempting && !hash && failed && (
          <ContentWrapper gap="sm">
            <RowBetween>
              <TYPE.mediumHeader>
                <span role="img" aria-label="wizard-icon" style={{ marginRight: '0.5rem' }}>
                  ⚠️
                </span>
                Error!
              </TYPE.mediumHeader>
              <CloseIcon onClick={wrappedOnDismiss} />
            </RowBetween>
            <TYPE.subHeader style={{ textAlign: 'center' }}>
              Your transaction couldn&apos;t be submitted.
              <br />
              You may have to increase your Gas Price (GWEI) settings!
            </TYPE.subHeader>
          </ContentWrapper>
        )}
      </div>
    </BootStrapModal>
  )
}
