import React from 'react'
import styled from 'styled-components'
import { DropdownButton, StyledMenu, StyledMenuText } from './styled'
import { BridgeToken } from 'constants/index'
import { ChevronDown } from 'react-feather'
import MenuItem from '@mui/material/MenuItem'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import hokklogo from '../../assets/images/token-hokk.png'

const tokens: {
  [token in BridgeToken]: {
    logo: string
    name: string
  }
} = {
  [BridgeToken.HOKK]: {
    logo: hokklogo,
    name: 'HOKK'
  }
}

const supportedTokens: Array<BridgeToken> = [BridgeToken.HOKK]

const Wrapper = styled.div`
  background: ${({ theme }) => theme.bg9};
  width: 100%;
  padding: 16px;
  margin: 24px 0px;
  display: flex;
  align-items: center;
  border-radius: 60px;
  font-size: 20px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 8px;
    font-size: 16px;
  `};
`

const TokenLogo = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 12px;

  img {
    width: 32px;
    margin-right: 12px;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    img {
      width: 24px;
    }
  `};
`

const TokenText = styled.div`
  flex: 1;
`

export default function TokenSelector({
  token,
  onChange
}: {
  token: BridgeToken
  onChange: (token_: BridgeToken) => void
}) {
  const selectToken = (_token: BridgeToken) => {
    onChange(_token)
  }

  return (
    <Wrapper>
      <TokenLogo>
        <img src={tokens[token].logo} alt="Token Logo" />
      </TokenLogo>

      <TokenText>{tokens[token].name}</TokenText>

      <PopupState variant="popover">
        {popupState => (
          <>
            <DropdownButton {...bindTrigger(popupState)}>
              <ChevronDown size={24} />
            </DropdownButton>
            <StyledMenu {...bindMenu(popupState)}>
              {supportedTokens.map((_token, idx) => (
                <MenuItem
                  onClick={() => {
                    popupState.close()
                    selectToken(_token)
                  }}
                  key={idx}
                >
                  <img src={tokens[_token].logo} alt={tokens[_token].name + ' Logo'} width={24} />
                  <StyledMenuText> {tokens[_token].name} </StyledMenuText>
                </MenuItem>
              ))}
            </StyledMenu>
          </>
        )}
      </PopupState>
    </Wrapper>
  )
}
