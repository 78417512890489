import React, { useCallback, useState } from 'react'
import { AutoColumn } from '../../components/Column'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { ChainId, ETHER, JSBI, TokenAmount } from 'sdk'
import { RouteComponentProps } from 'react-router-dom'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { useCurrency } from '../../hooks/Tokens'
import { useWalletModalToggle } from '../../state/application/hooks'
import { TYPE } from '../../theme'

import { RowBetween } from '../../components/Row'
import { CardSection, DataCard } from '../../components/earn/styled'
import { ButtonPrimary, ButtonWhite } from '../../components/Button'
import StakingModal from '../../components/earn/StakingModal'
import { useStakingInfo } from '../../state/stake/hooks'
import UnstakingModal from '../../components/earn/UnstakingModal'
import ClaimRewardModal from '../../components/earn/ClaimRewardModal'
import { useTokenBalance } from '../../state/wallet/hooks'
import { useWeb3React } from '@web3-react/core'
import { useColor } from '../../hooks/useColor'
import { CountUp } from 'use-count-up'

import { wrappedCurrency } from '../../utils/wrappedCurrency'
import { currencyId } from '../../utils/currencyId'
import { useTotalSupply } from '../../data/TotalSupply'
import { usePair } from '../../data/Reserves'
import usePrevious from '../../hooks/usePrevious'
import useUSDCPrice from '../../utils/useUSDCPrice'
import { BIG_INT_ZERO } from '../../constants'
import useGovernanceToken from '../../hooks/useGovernanceToken'
import thunder from '../../assets/svg/thunder.svg'
import iconStar from '../../assets/images/iconStar.svg'
import { CHAIN_INFO } from 'constants/chains'

const PageWrapper = styled(AutoColumn)`
  width: 100%;
`

const PositionInfo = styled(AutoColumn)<{ dim: any }>`
  position: relative;
  width: 100%;
  opacity: ${({ dim }) => (dim ? 0.6 : 1)};
`

const BottomSection = styled(AutoColumn)`
  border-radius: 12px 12px 0 0;
  width: 100%;
  position: relative;
`

const StyledDataCard = styled(DataCard)<{ bgColor?: any; showBackground?: any }>`
  background: #000;
  z-index: 2;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`

const StyledBottomCard = styled(DataCard)<{ dim: any }>`
  background: ${({ theme }) => theme.bg6};
  opacity: ${({ dim }) => (dim ? 0.4 : 1)};
  margin-top: -40px;
  padding: 0 1.25rem 1rem 1.25rem;
  padding-top: 32px;
  z-index: 1;
`

const PoolData = styled(DataCard)`
  background: none;
  border: 1px solid ${({ theme }) => theme.bg4};
  padding: 1rem;
  z-index: 1;
`

const VoteCard = styled(DataCard)`
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #27ae60 0%, #000000 100%);
  overflow: hidden;
`

const DataRow = styled(RowBetween)`
  justify-content: center;
  gap: 12px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 12px;
  `};
`

export default function Manage({
  match: {
    params: { currencyIdA, currencyIdB }
  }
}: RouteComponentProps<{ currencyIdA: string; currencyIdB: string }>) {
  const { account, chainId } = useWeb3React()

  // get currencies and pair
  const [currencyA, currencyB] = [useCurrency(currencyIdA), useCurrency(currencyIdB)]
  const tokenA = wrappedCurrency(currencyA ?? undefined, chainId)
  const tokenB = wrappedCurrency(currencyB ?? undefined, chainId)
  const govToken = useGovernanceToken()

  const [, stakingTokenPair] = usePair(tokenA, tokenB)
  const stakingInfo = useStakingInfo(undefined, stakingTokenPair)?.[0]

  // detect existing unstaked LP position to show add button if none found
  const userLiquidityUnstaked = useTokenBalance(account ?? undefined, stakingInfo?.stakedAmount?.token)
  const showAddLiquidityButton = Boolean(stakingInfo?.stakedAmount?.equalTo('0') && userLiquidityUnstaked?.equalTo('0'))

  // toggle for staking modal and unstaking modal
  const [showStakingModal, setShowStakingModal] = useState(false)
  const [showUnstakingModal, setShowUnstakingModal] = useState(false)
  const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)

  // fade cards if nothing staked or nothing earned yet
  const disableTop = !stakingInfo?.stakedAmount || stakingInfo.stakedAmount.equalTo(JSBI.BigInt(0))

  const token = currencyA === ETHER ? tokenB : tokenA
  const WETH = currencyA === ETHER ? tokenA : tokenB
  const backgroundColor = useColor(token)

  // get WETH value of staked LP tokens
  const totalSupplyOfStakingToken = useTotalSupply(stakingInfo?.stakedAmount?.token)
  let valueOfTotalStakedAmountInWETH: TokenAmount | undefined
  if (totalSupplyOfStakingToken && stakingTokenPair && stakingInfo && WETH) {
    // take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
    valueOfTotalStakedAmountInWETH = new TokenAmount(
      WETH,
      JSBI.divide(
        JSBI.multiply(
          JSBI.multiply(stakingInfo.totalStakedAmount.raw, stakingTokenPair.reserveOf(WETH).raw),
          JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
        ),
        totalSupplyOfStakingToken.raw
      )
    )
  }

  const countUpAmount = stakingInfo?.earnedAmount?.toFixed(6) ?? '0'
  const countUpAmountPrevious = usePrevious(countUpAmount) ?? '0'

  // get the USD value of staked WETH
  const USDPrice = useUSDCPrice(WETH)
  const valueOfTotalStakedAmountInUSDC =
    valueOfTotalStakedAmountInWETH && USDPrice?.quote(valueOfTotalStakedAmountInWETH)

  const toggleWalletModal = useWalletModalToggle()

  const handleDepositClick = useCallback(() => {
    if (account) {
      setShowStakingModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  return (
    <>
      <div>
        <div className="cardWrap treatMining">
          <h4 className="titleHead d-flex">
            {currencyA?.symbol}-{currencyB?.symbol} Liquidity Mining
            <ul className="iconImg mg-l-auto">
              <DoubleCurrencyLogo currency0={currencyA ?? undefined} currency1={currencyB ?? undefined} size={24} />
            </ul>
          </h4>
          <div className="rows">
            <div className="colHalf">
              <div className="cardIn">
                <div className="textWrap">
                  <h5 className="whtClr liteHead">Total deposits</h5>
                  <h4 className="d-flex align-item-center whtClr strongHead iconTx">
                    {/* <img src={btc} alt='cripto icons' />  */}
                    {valueOfTotalStakedAmountInUSDC
                      ? `$${valueOfTotalStakedAmountInUSDC.toFixed(0, { groupSeparator: ',' })}`
                      : `${valueOfTotalStakedAmountInWETH?.toSignificant(4, { groupSeparator: ',' }) ?? '-'} ${
                          CHAIN_INFO[chainId as ChainId].nativeCurrency.symbol
                        }`}
                  </h4>
                </div>
              </div>
            </div>
            <div className="colHalf">
              <div className="cardIn">
                <div className="textWrap">
                  <h5 className="whtClr liteHead">Emission Rate</h5>
                  <h4 className="d-flex align-item-center whtClr iconTx strongHead">
                    {stakingInfo
                      ? stakingInfo.active
                        ? `${stakingInfo.poolRewardsPerBlock.toSignificant(3, { groupSeparator: ',' })} 
                ${govToken?.symbol} / block`
                        : `0 ${govToken?.symbol} / block`
                      : '-'}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          {showAddLiquidityButton && (
            <div className="rows">
              <div className="colFull">
                <div className="cardIn blueGrade tokenWrap textLg">
                  <p className="whtClr">Step1. Get UNI - V2 Liquidity tokens</p>
                  <p className="whtClr">{`UNI-V2 LP tokens are required. Once you've added liquidity to the ${currencyA?.symbol}-${currencyB?.symbol} pool you can stake your liquidity tokens on this page.`}</p>

                  <div className="boxShdwNone">
                    <ButtonPrimary
                      padding="8px"
                      borderRadius="8px"
                      width={'fit-content'}
                      as={Link}
                      className="btnMain boxShdwNone"
                      to={`/add/${currencyA && currencyId(currencyA)}/${currencyB && currencyId(currencyB)}`}
                    >
                      {`Add ${currencyA?.symbol}-${currencyB?.symbol} liquidity`}
                    </ButtonPrimary>
                  </div>
                </div>
              </div>
            </div>
          )}
          {stakingInfo && (
            <>
              <StakingModal
                isOpen={showStakingModal}
                onDismiss={() => setShowStakingModal(false)}
                stakingInfo={stakingInfo}
                userLiquidityUnstaked={userLiquidityUnstaked}
              />
              <UnstakingModal
                isOpen={showUnstakingModal}
                onDismiss={() => setShowUnstakingModal(false)}
                stakingInfo={stakingInfo}
              />
              <ClaimRewardModal
                isOpen={showClaimRewardModal}
                onDismiss={() => setShowClaimRewardModal(false)}
                stakingInfo={stakingInfo}
              />
            </>
          )}
          <div className="rows liquBox">
            <div className="colFull">
              <div className={`cardIn depositsWrap ${!showAddLiquidityButton ? `blueGrade tokenWrap textLg` : ``}`}>
                <h5 className={`fontWeightBold ${showAddLiquidityButton ? `grayClr` : ``}`}>Your liquidity deposits</h5>
                <div className="d-flex widthFull">
                  <h4
                    className={`widthFull fontWeightBold align-item-center d-flex ${
                      showAddLiquidityButton ? `grayClr` : ``
                    }`}
                  >
                    <span> {stakingInfo?.stakedAmount?.toSignificant(6) ?? '-'}</span>{' '}
                    <small className="mg-l-auto">
                      {' '}
                      UNI-V2 {currencyA?.symbol}-{currencyB?.symbol}
                    </small>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          {/* <StyledBottomCard dim={stakingInfo?.stakedAmount?.equalTo(JSBI.BigInt(0))}> */}
          <div className="rows liquBox">
            <div className="colFull my-3">
              <div className="resutlWrap">
                {/* <h5 className='grayClr widthFull fontWeightBold'>
                                      <span>Your unclaimed TREATS {stakingInfo?.earnedAmount && JSBI.notEqual(BIG_INT_ZERO, stakingInfo?.earnedAmount?.raw) && (
                                          <ButtonWhite
                                              padding="8px"
                                              borderRadius="8px"
                                              width="fit-content"
                                              onClick={() => setShowClaimRewardModal(true)}
                                          >
                                              Claim
                                          </ButtonWhite>
                                      )}</span>
                                  </h5> */}
                <RowBetween>
                  <h5 className="grayClr widthFull fontWeightBold">
                    <span>Your unclaimed deposits</span>
                  </h5>
                  {stakingInfo?.earnedAmount && JSBI.notEqual(BIG_INT_ZERO, stakingInfo?.earnedAmount?.raw) && (
                    <ButtonWhite
                      padding="12px 40px"
                      borderRadius="1518.06px"
                      width="fit-content"
                      onClick={() => setShowClaimRewardModal(true)}
                    >
                      Claim
                    </ButtonWhite>
                  )}
                </RowBetween>

                <RowBetween style={{ alignItems: 'baseline' }}>
                  <TYPE.largeHeader fontSize={24} fontWeight={600}>
                    <CountUp
                      key={countUpAmount}
                      isCounting
                      decimalPlaces={4}
                      start={parseFloat(countUpAmountPrevious)}
                      end={parseFloat(countUpAmount)}
                      thousandsSeparator={','}
                      duration={1}
                    />
                  </TYPE.largeHeader>
                  <TYPE.black fontSize={16} fontWeight={500}>
                    <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px ' }}>
                      <img src={thunder} alt="thunder icon" className="mtm4" />
                    </span>
                  </TYPE.black>
                </RowBetween>
                <hr className="dividerHr" />
                <p className="d-flex iconTx grayClr font18">
                  <img src={iconStar} alt="star icon" className="mtm4" /> When you withdraw, the contract will
                  automagically claim TREATS on your behalf!
                </p>
                {/* <div className='withdrwBtn mt15'><Withdrawmodals showAddLiquidityButton={showAddLiquidityButton} stakingInfo={stakingInfo} currency={currencyA} userLiquidityUnstaked={userLiquidityUnstaked} /></div> */}
                {/* {!showAddLiquidityButton && (
                                      <div className='withdrwBtn mt15'>
                                          <DataRow style={{ marginBottom: '1rem' }}>
                                              {stakingInfo && stakingInfo.active && (
                                                  <ButtonPrimary padding="8px" borderRadius="8px" width="160px" onClick={handleDepositClick}>
                                                      {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) ? 'Deposit' : 'Deposit UNI-V2 LP Tokens'}
                                                  </ButtonPrimary>
                                              )}

                                              {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) && (
                                                  <>
                                                      <ButtonPrimary
                                                          padding="8px"
                                                          borderRadius="8px"
                                                          width="160px"
                                                          onClick={() => setShowUnstakingModal(true)}
                                                      >
                                                          Withdraw
                                                      </ButtonPrimary>
                                                  </>
                                              )}
                                          </DataRow>
                                      </div>
                                  )} */}
                <div>
                  <div className="d-flex">
                    {stakingInfo && stakingInfo.active && (
                      <button className="btnMain x-large-btn boxShdwNone" onClick={handleDepositClick}>
                        {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0))
                          ? 'Deposit'
                          : 'Deposit UNI-V2 LP Tokens'}
                      </button>
                    )}

                    {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) && (
                      <button
                        className="btnMain bglightBlue boxShdwNone x-large-btn"
                        onClick={() => setShowUnstakingModal(true)}
                      >
                        Withdraw
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {!userLiquidityUnstaked ? null : userLiquidityUnstaked.equalTo('0') ? null : !stakingInfo?.active ? null : (
              <TYPE.main>{userLiquidityUnstaked.toSignificant(6)} UNI-V2 LP tokens available</TYPE.main>
            )}
          </div>
          {/* </StyledBottomCard> */}
        </div>
      </div>
    </>
  )
}
