import { Currency, Percent, Price } from 'sdk'
import React, { useContext } from 'react'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { AutoColumn } from '../../components/Column'
import { AutoRow } from '../../components/Row'
import { ONE_BIPS } from '../../constants'
import { Field } from '../../state/mint/actions'
import { TYPE } from '../../theme'
import { trimNativeSymbol } from 'utils'
import { useWeb3React } from '@web3-react/core'

export function PoolPriceBar({
  currencies,
  noLiquidity,
  poolTokenPercentage,
  price
}: {
  currencies: { [field in Field]?: Currency }
  noLiquidity?: boolean
  poolTokenPercentage?: Percent
  price?: Price
}) {
  const theme = useContext(ThemeContext)
  const { chainId } = useWeb3React()

  return (
    <AutoColumn gap="md">
      <AutoRow justify="space-around" gap="4px">
        <AutoColumn justify="center">
          <TYPE.black color={theme.text6} fontSize={18} fontWeight={'700'}>
            {price?.toSignificant(6) ?? '-'}
          </TYPE.black>
          <Text fontWeight={500} fontSize={18} color={theme.text2} pt={1}>
            {trimNativeSymbol(chainId, currencies[Field.CURRENCY_B])} per{' '}
            {trimNativeSymbol(chainId, currencies[Field.CURRENCY_A])}
          </Text>
        </AutoColumn>
        <AutoColumn justify="center">
          <TYPE.black color={theme.text6} fontSize={18} fontWeight={'700'}>
            {price?.invert()?.toSignificant(6) ?? '-'}
          </TYPE.black>
          <Text fontWeight={500} fontSize={18} color={theme.text2} pt={1}>
            {trimNativeSymbol(chainId, currencies[Field.CURRENCY_A])} per{' '}
            {trimNativeSymbol(chainId, currencies[Field.CURRENCY_B])}
          </Text>
        </AutoColumn>
        <AutoColumn justify="center">
          <TYPE.black color={theme.text6} fontSize={18} fontWeight={'700'}>
            {noLiquidity && price
              ? '100'
              : (poolTokenPercentage?.lessThan(ONE_BIPS) ? '<0.01' : poolTokenPercentage?.toFixed(2)) ?? '0'}
            %
          </TYPE.black>
          <Text fontWeight={500} fontSize={18} color={theme.text2} pt={1}>
            Share of Pool
          </Text>
        </AutoColumn>
      </AutoRow>
    </AutoColumn>
  )
}
