import React, { useState } from 'react'
import Modal from '../Modal'
import { AutoColumn } from '../Column'
import styled from 'styled-components'
import { RowBetween } from '../Row'
import { TYPE, CloseIcon } from '../../theme'
import { ButtonError } from '../Button'
import { SingleStakingInfo, useGetETHReward } from '../../state/stake/hooks'
import { useMasterBreederContract } from '../../hooks/useContract'
import { SubmittedView, LoadingView } from '../ModalViews'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import FormattedCurrencyAmount from '../FormattedCurrencyAmount'
import { useWeb3React } from '@web3-react/core'
// import { ZERO_ADDRESS } from '../../constants'
import useGovernanceToken from 'hooks/useGovernanceToken'
import { Modal as BootStrapModal } from 'react-bootstrap'
import { getNativeSymbol } from 'utils'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

interface StakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  stakingInfo: SingleStakingInfo
}

export default function SingleUnstakingModal({ isOpen, onDismiss, stakingInfo }: StakingModalProps) {
  const ethReward = useGetETHReward(stakingInfo ?? undefined)
  const { account, chainId } = useWeb3React()

  const govToken = useGovernanceToken()

  // monitor call to help UI loading state
  const addTransaction = useTransactionAdder()
  const [hash, setHash] = useState<string | undefined>()
  const [attempting, setAttempting] = useState(false)
  const [failed, setFailed] = useState<boolean>(false)

  const referral = '0x674aC2fA134B37E38f964053Ca5D510819de3E3c'

  function wrappedOnDismiss() {
    setHash(undefined)
    setAttempting(false)
    setFailed(false)
    onDismiss()
  }

  const masterBreeder = useMasterBreederContract()

  async function onWithdraw() {
    if (masterBreeder && stakingInfo?.stakedAmount) {
      setAttempting(true)
      await masterBreeder
        .withdraw(stakingInfo.pid, `0x${stakingInfo.stakedAmount.raw.toString(16)}`, referral)
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Withdraw deposited liquidity`
          })
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          if (error?.code === -32603) {
            setFailed(true)
          }
          console.log(error)
        })
    }
  }

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!stakingInfo?.stakedAmount) {
    error = error ?? 'Enter an amount'
  }

  return (
    <BootStrapModal show={isOpen} onClose={wrappedOnDismiss} centered={true}>
      <div className="popupWrapper widra_pp">
        {!attempting && !hash && !failed && (
          <>
            <RowBetween>
              {
                /*
                <div className="modalHeader">
                  <h3>Withdraw</h3>
                </div>
                */
              }
              <span></span>
              <CloseIcon onClick={wrappedOnDismiss} />
            </RowBetween>

            <div className="widraBx">
              <div className="rows whtClr">
                <div className="colFull">
                  <h3 className="m-12">Deposited {stakingInfo.stakedAmount.currency.symbol}</h3>
                  <div className="bglightBlu">
                    {<FormattedCurrencyAmount currencyAmount={stakingInfo.stakedAmount} />}
                  </div>
                </div>
                <div className="colFull">
                  <h3 className="m-12">Unclaimed {govToken?.symbol}</h3>
                  <div className="bglightBlu">
                    {<FormattedCurrencyAmount currencyAmount={stakingInfo?.earnedAmount} />}
                  </div>
                </div>
              </div>
              {
                /*
                <p className="blueClr">
                  When you withdraw, your {govToken?.symbol} is claimed and your liquidity is removed from the mining pool
                </p>
                */
              }

              <div className='mt-4 text-center'>
                <ButtonError
                  className="btnMain x-large-btn widthFull"
                  disabled={!!error}
                  error={!!error && !!stakingInfo?.stakedAmount}
                  onClick={onWithdraw}
                >
                  {error ?? 'Withdraw & Claim'}
                </ButtonError>
              </div>
            </div>
          </>
        )}
        {attempting && !hash && !failed && (
          <LoadingView onDismiss={wrappedOnDismiss}>
            <AutoColumn gap="12px" justify={'center'}>
              <TYPE.body fontSize={20}>
                Withdrawing {stakingInfo?.stakedAmount?.toSignificant(4)} {stakingInfo.stakedAmount.currency.symbol}
              </TYPE.body>
              <TYPE.body fontSize={20}>
                Claiming {stakingInfo?.earnedAmount?.toSignificant(4)} {govToken?.symbol}
              </TYPE.body>
              <RowBetween>
                <TYPE.white>Unlocked Amount&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</TYPE.white>
                <TYPE.white>
                  <span role="img" aria-label="wizard-icon" style={{ marginRight: '0.5rem' }}>
                    🔓
                  </span>
                  {stakingInfo
                    ? stakingInfo.active
                      ? `${stakingInfo.unlockedEarnedAmount.toSignificant(4, { groupSeparator: ',' })} ${govToken?.symbol
                      }`
                      : `0 ${govToken?.symbol}`
                    : '-'}
                </TYPE.white>
              </RowBetween>
              <RowBetween>
                <TYPE.white>Locked Amount</TYPE.white>
                <TYPE.white>
                  <span role="img" aria-label="wizard-icon" style={{ marginRight: '0.5rem' }}>
                    🔒
                  </span>
                  {stakingInfo
                    ? stakingInfo.active
                      ? `${stakingInfo.lockedEarnedAmount.toSignificant(4, { groupSeparator: ',' })} ${govToken?.symbol
                      }`
                      : `0 ${govToken?.symbol}`
                    : '-'}
                </TYPE.white>
              </RowBetween>
              {stakingInfo?.pid === 0 && (
                <TYPE.body fontSize={20}>
                  Claiming {ethReward?.toSignificant(4)} {getNativeSymbol(chainId)}
                </TYPE.body>
              )}
            </AutoColumn>
          </LoadingView>
        )}
        {hash && !failed && (
          <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
            <AutoColumn gap="12px" justify={'center'}>
              <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
              <TYPE.body fontSize={20}>Withdrew {stakingInfo.stakedAmount.currency.symbol}!</TYPE.body>
              <TYPE.body fontSize={20}>Claimed {govToken?.symbol}!</TYPE.body>
              <TYPE.body fontSize={20}>Claimed {getNativeSymbol(chainId)}!</TYPE.body>
            </AutoColumn>
          </SubmittedView>
        )}
        {!attempting && !hash && failed && (
          <ContentWrapper gap="sm">
            <RowBetween>
              <TYPE.mediumHeader>
                <span role="img" aria-label="wizard-icon" style={{ marginRight: '0.5rem' }}>
                  ⚠️
                </span>
                Error!
              </TYPE.mediumHeader>
              <CloseIcon onClick={wrappedOnDismiss} />
            </RowBetween>
            <TYPE.subHeader style={{ textAlign: 'center' }}>
              Your transaction couldn&apos;t be submitted.
              <br />
              You may have to increase your Gas Price (GWEI) settings!
            </TYPE.subHeader>
          </ContentWrapper>
        )}
      </div>
    </BootStrapModal>
  )
}
