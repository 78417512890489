import React, { useContext, useRef, useState } from 'react'
import { Settings, X } from 'react-feather'
import styled, { ThemeContext } from 'styled-components'
import { useModalOpen, useToggleSettingsMenu } from '../../state/application/hooks'
import {
  useExpertModeManager,
} from '../../state/user/hooks'

const StyledMenuIcon = styled(Settings)`
  height: 20px;
  width: 20px;

  > * {
    stroke: ${({ theme }) => theme.text2};
  }

  :hover {
    opacity: 0.7;
  }
`

const StyledMenuButton = styled.button`
  position: relative;
  height: 35px;
  border: none;
  background-color: transparent;
  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;
  z-index: 2;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
  }

  svg {
    margin-top: 2px;
  }
`
const EmojiWrapper = styled.div`
  position: absolute;
  bottom: -6px;
  right: 0px;
  font-size: 14px;
`

export default function SettingsButton() {

  const [expertMode, toggleExpertMode] = useExpertModeManager()
  const toggle = useToggleSettingsMenu()

  return (
    <StyledMenuButton onClick={toggle} id="open-settings-dialog-button">
      <StyledMenuIcon />
      {expertMode ? (
        <EmojiWrapper>
          <span role="img" aria-label="wizard-icon">
            🧙
          </span>
        </EmojiWrapper>
      ) : null}
    </StyledMenuButton>
  )
}
