import { CHAIN_INFO } from 'constants/chains'
import React, { useContext } from 'react'
import { AlertCircle } from 'react-feather'
import { ChainId } from 'sdk'
import styled, { ThemeContext } from 'styled-components/macro'

import { TYPE } from '../../theme'
import { AutoColumn } from '../Column'
import { AutoRow } from '../Row'

const RowNoFlex = styled(AutoRow)`
  flex-wrap: nowrap;
`

export default function FailedNetworkSwitchPopup({ chainId }: { chainId: ChainId }) {
  const chainInfo = CHAIN_INFO[chainId]
  const theme = useContext(ThemeContext)

  return (
    <RowNoFlex>
      <div style={{ paddingRight: 16 }}>
        <AlertCircle color={theme.red1} size={24} />
      </div>
      <AutoColumn gap="8px">
        <TYPE.body fontWeight={500}>
          Failed to switch networks from the HOKKFi. In order to use HOKKFi on {chainInfo.label}, you must change the
          network in your wallet.
        </TYPE.body>
      </AutoColumn>
    </RowNoFlex>
  )
}
