import { ChainId, JSBI, Percent, Token, WETH } from 'sdk'
import { BigNumber } from 'ethers'

export const ZERO = JSBI.BigInt(0)

export const CHART_API_URL = 'https://hokk-ohlc.herokuapp.com'

export const VALIDATOR_URL = 'https://hokkbridge.link/validator/'

export const ROUTER_ADDRESS: { [chainId: number]: string } = {
  [ChainId.MAINNET]: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  [ChainId.GÖRLI]: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  [ChainId.SEPOLIA]: '',

  [ChainId.BSC_MAINNET]: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
  [ChainId.BSC_TESTNET]: '0x10ED43C718714eb63d5aA57B78B54704E256024E',

  [ChainId.HECO_MAINNET]: '0xed7d5f38c79115ca12fe6c0041abb22f0a06c300',
  [ChainId.HECO_TESTNET]: '0xed7d5f38c79115ca12fe6c0041abb22f0a06c300'
}

export const FACTORY_ADDRESS: { [chainId: number]: string } = {
  [ChainId.MAINNET]: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
  [ChainId.GÖRLI]: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
  [ChainId.SEPOLIA]: '',

  [ChainId.BSC_MAINNET]: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73',
  [ChainId.BSC_TESTNET]: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73',

  [ChainId.HECO_MAINNET]: '0xb0b670fc1F7724119963018DB0BfA86aDb22d941',
  [ChainId.HECO_TESTNET]: '0xb0b670fc1F7724119963018DB0BfA86aDb22d941'
}

export const BRIDGE_X_ADDRESS: { [chainId: number]: string } = {
  [ChainId.MAINNET]: '',
  [ChainId.SEPOLIA]: '0xaBEa9c7Af18346d32c1e48A3602486F843a7B0A3',
  [ChainId.GÖRLI]: '',

  [ChainId.BSC_MAINNET]: '',
  [ChainId.BSC_TESTNET]: '',

  [ChainId.HECO_MAINNET]: '',
  [ChainId.HECO_TESTNET]: ''
}

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'
export const AUSDC_ADDRESS = '0xBcca60bB61934080951369a648Fb03DF4F96263C'
export const AWETH_ADDRESS = '0x030bA81f1c18d280636F32af80b9AAd02Cf0854e'

export const USDC_DECIMALS = 6
export const WETH_DECIMALS = 18

export const HOKKNFT_IMAGE = 'https://hokk.mypinata.cloud/ipfs/QmZtXvyaKWfJjv9GFaSFquqox7kRqi3wSgcCz82NfZtxYj/'

export { PRELOADED_PROPOSALS } from './proposals'

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId: number]: Token[]
}

export const AMPL = new Token(
  ChainId.MAINNET as number,
  '0xD46bA6D942050d489DBd938a2C909A5d5039A161',
  9,
  'AMPL',
  'Ampleforth'
)
export const FEI = new Token(
  ChainId.MAINNET as number,
  '0x956F47F50A910163D8BF957Cf5846D573E7f87CA',
  18,
  'FEI',
  'Fei USD'
)
export const TRIBE = new Token(
  ChainId.MAINNET as number,
  '0xc7283b66Eb1EB5FB86327f08e1B5816b0720212B',
  18,
  'TRIBE',
  'Tribe'
)
export const FRAX = new Token(
  ChainId.MAINNET as number,
  '0x853d955aCEf822Db058eb8505911ED77F175b99e',
  18,
  'FRAX',
  'Frax'
)
export const FXS = new Token(
  ChainId.MAINNET as number,
  '0x3432B6A60D23Ca0dFCa7761B7ab56459D9C964D0',
  18,
  'FXS',
  'Frax Share'
)
export const renBTC = new Token(
  ChainId.MAINNET as number,
  '0xEB4C2781e4ebA804CE9a9803C67d0893436bB27D',
  8,
  'renBTC',
  'renBTC'
)

export const BUSD = new Token(
  ChainId.BSC_MAINNET as number,
  '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  18,
  'BUSD',
  'Binance USD'
)
export const BETH = new Token(
  ChainId.BSC_MAINNET as number,
  '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  18,
  'ETH',
  'Ethereum Token'
)

export const HUSD = new Token(
  ChainId.HECO_MAINNET as number,
  '0x0298c2b32eae4da002a15f36fdf7615bea3da047',
  18,
  'Heco-Peg HUSD',
  'Heco USD'
)
export const HETH = new Token(
  ChainId.HECO_MAINNET as number,
  '0x64ff637fb478863b7468bc97d30a5bf3a428a1fd',
  18,
  'ETH',
  'Heco-Peg ETH Token'
)

// Block time here is slightly higher (~1s) than average in order to avoid ongoing proposals past the displayed time
export const AVERAGE_BLOCK_TIME_IN_SECS = 13
export const PROPOSAL_LENGTH_IN_BLOCKS = 40_320
export const PROPOSAL_LENGTH_IN_SECS = AVERAGE_BLOCK_TIME_IN_SECS * PROPOSAL_LENGTH_IN_BLOCKS

export const GOVERNANCE_ADDRESS = '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F'

export const TIMELOCK_ADDRESS = '0x1a9C8182C09F50C8318d769245beA52c32BE35BC'

export const HOKK: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET as number,
    '0xe87e15B9c7d989474Cb6d8c56b3DB4eFAD5b21E8',
    18,
    'HOKK',
    'Hokkaido Inu'
  ),
  [ChainId.GÖRLI]: new Token(ChainId.GÖRLI as number, ZERO_ADDRESS, 18, 'HOKK', 'Hokkaido Inu'),
  [ChainId.SEPOLIA]: new Token(ChainId.SEPOLIA as number, ZERO_ADDRESS, 18, 'HOKK', 'Hokkaido Inu'),

  [ChainId.BSC_MAINNET]: new Token(
    ChainId.BSC_MAINNET as number,
    '0xe87e15B9c7d989474Cb6d8c56b3DB4eFAD5b21E8',
    18,
    'HOKK',
    'Hokkaido Inu'
  ),
  [ChainId.BSC_TESTNET]: new Token(ChainId.BSC_TESTNET as number, ZERO_ADDRESS, 18, 'HOKK', 'Hokkaido Inu'),

  [ChainId.HECO_MAINNET]: new Token(
    ChainId.HECO_MAINNET as number,
    '0x4EbF49cc2d2dd029e5CFDf868316385DfFd94c6A',
    18,
    'HOKK',
    'Hokkaido Inu'
  ),
  [ChainId.HECO_TESTNET]: new Token(ChainId.HECO_TESTNET as number, ZERO_ADDRESS, 18, 'HOKK', 'Hokkaido Inu')
}

export const USDC: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET as number,
    '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    6,
    'USDC',
    'USD//C'
  ),
  [ChainId.GÖRLI]: new Token(ChainId.GÖRLI as number, ZERO_ADDRESS, 6, 'USDC', 'USD//C'),
  [ChainId.SEPOLIA]: new Token(ChainId.SEPOLIA as number, ZERO_ADDRESS, 6, 'USDC', 'USD//C'),

  [ChainId.BSC_MAINNET]: new Token(
    ChainId.BSC_MAINNET as number,
    '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    18,
    'USDC',
    'Binance-Peg USD Coin'
  ),
  [ChainId.BSC_TESTNET]: new Token(ChainId.BSC_TESTNET as number, ZERO_ADDRESS, 18, 'USDC', 'Binance-Peg USD Coin'),

  [ChainId.HECO_MAINNET]: new Token(
    ChainId.HECO_MAINNET as number,
    '0x9362Bbef4B8313A8Aa9f0c9808B80577Aa26B73B',
    18,
    'USDC',
    'Heco-Peg USDCHECO'
  ),
  [ChainId.HECO_TESTNET]: new Token(ChainId.HECO_TESTNET as number, ZERO_ADDRESS, 18, 'USDC', 'Heco-Peg USDCHECO')
}

export const USDT: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET as number,
    '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    6,
    'USDT',
    'Tether USD'
  ),
  [ChainId.GÖRLI]: new Token(ChainId.GÖRLI as number, ZERO_ADDRESS, 6, 'USDT', 'Tether USD'),
  [ChainId.SEPOLIA]: new Token(ChainId.SEPOLIA as number, ZERO_ADDRESS, 6, 'USDT', 'Tether USD'),

  [ChainId.BSC_MAINNET]: new Token(
    ChainId.BSC_MAINNET as number,
    '0x55d398326f99059fF775485246999027B3197955',
    18,
    'USDT',
    'Wrapped USDT'
  ),
  [ChainId.BSC_TESTNET]: new Token(ChainId.BSC_TESTNET as number, ZERO_ADDRESS, 18, 'USDT', 'Wrapped USDT'),

  [ChainId.HECO_MAINNET]: new Token(
    ChainId.HECO_MAINNET as number,
    '0xa71EdC38d189767582C38A3145b5873052c3e47a',
    18,
    'USDT',
    'Heco-Peg USDTHECO'
  ),
  [ChainId.HECO_TESTNET]: new Token(ChainId.HECO_TESTNET as number, ZERO_ADDRESS, 18, 'USDT', 'Heco-Peg USDTHECO')
}

export const DAI: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET as number,
    '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    18,
    'DAI',
    'Dai Stablecoin'
  ),
  [ChainId.GÖRLI]: new Token(ChainId.GÖRLI as number, ZERO_ADDRESS, 18, 'DAI', 'Dai Stablecoin'),
  [ChainId.SEPOLIA]: new Token(ChainId.SEPOLIA as number, ZERO_ADDRESS, 18, 'DAI', 'Dai Stablecoin'),

  [ChainId.BSC_MAINNET]: new Token(
    ChainId.BSC_MAINNET as number,
    '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
    18,
    'DAI',
    'DAI USD'
  ),
  [ChainId.BSC_TESTNET]: new Token(ChainId.BSC_TESTNET as number, ZERO_ADDRESS, 18, 'DAI', 'DAI USD'),

  [ChainId.HECO_MAINNET]: new Token(
    ChainId.HECO_MAINNET as number,
    '0x3D760a45D0887DFD89A2F5385a236B29Cb46ED2a',
    18,
    'DAI',
    'Heco-Peg DAI'
  ),
  [ChainId.HECO_TESTNET]: new Token(ChainId.HECO_TESTNET as number, ZERO_ADDRESS, 18, 'DAI', 'Heco-Peg DAI')
}

export const WBTC: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET as number,
    '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
    8,
    'WBTC',
    'Wrapped BTC'
  ),
  [ChainId.GÖRLI]: new Token(ChainId.GÖRLI as number, ZERO_ADDRESS, 8, 'WBTC', 'Wrapped BTC'),
  [ChainId.SEPOLIA]: new Token(ChainId.SEPOLIA as number, ZERO_ADDRESS, 8, 'WBTC', 'Wrapped BTC'),

  [ChainId.BSC_MAINNET]: new Token(
    ChainId.BSC_MAINNET as number,
    '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    18,
    'WBTC',
    'Wrapped Bitcoin'
  ),
  [ChainId.BSC_TESTNET]: new Token(ChainId.BSC_TESTNET as number, ZERO_ADDRESS, 18, 'WBTC', 'Wrapped Bitcoin'),

  [ChainId.HECO_MAINNET]: new Token(
    ChainId.HECO_MAINNET as number,
    '0x66a79D23E58475D2738179Ca52cd0b41d73f0BEa',
    18,
    'HBTC',
    'Heco-Peg HBTC Token'
  ),
  [ChainId.HECO_TESTNET]: new Token(ChainId.HECO_TESTNET as number, ZERO_ADDRESS, 18, 'HBTC', 'Heco-Peg HBTC Token')
}

export const UNI: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET as number,
    '0xC40AF1E4fEcFA05Ce6BAb79DcD8B373d2E436c4E',
    18,
    'UNI',
    'Uniswap'
  ),
  [ChainId.GÖRLI]: new Token(ChainId.GÖRLI as number, ZERO_ADDRESS, 18, 'UNI', 'Uniswap'),
  [ChainId.SEPOLIA]: new Token(ChainId.SEPOLIA as number, ZERO_ADDRESS, 18, 'UNI', 'Uniswap'),

  [ChainId.BSC_MAINNET]: new Token(
    ChainId.BSC_MAINNET as number,
    '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    18,
    'CAKE',
    'PancakeSwap'
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET as number,
    '0xf73D010412Fb5835C310728F0Ba1b7DFDe88379A',
    18,
    'CAKE',
    'PancakeSwap'
  ),

  [ChainId.HECO_MAINNET]: new Token(
    ChainId.HECO_MAINNET as number,
    '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    18,
    'MDX',
    'MDEX Token'
  ),
  [ChainId.HECO_TESTNET]: new Token(
    ChainId.HECO_TESTNET as number,
    '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    18,
    'MDX',
    'MDEX Token'
  )
}

export const GOVERNANCE_TOKEN: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET as number,
    '0xb4e1bba882e1058dC9088c69F26f832394caBc77',
    18,
    'TREATS',
    'TREATS'
  ),
  [ChainId.GÖRLI]: new Token(ChainId.GÖRLI as number, ZERO_ADDRESS, 18, 'TREATS', 'TREATS'),
  [ChainId.SEPOLIA]: new Token(ChainId.SEPOLIA as number, ZERO_ADDRESS, 18, 'TREATS', 'TREATS')
}

export const MASTER_BREEDER: { [chainId: number]: string } = {
  [ChainId.MAINNET]: '0x174C4906A95040Ca45bCD3f925E89A8A5348F98f',
  [ChainId.GÖRLI]: ZERO_ADDRESS,
  [ChainId.SEPOLIA]: ZERO_ADDRESS
}

export const STAKING_MASTER_BREEDER: { [chainId: number]: string } = {
  [ChainId.MAINNET]: '0xB9aC15f843A36e6AfbA6a239514802233F83fB6f',
  [ChainId.GÖRLI]: ZERO_ADDRESS,
  [ChainId.SEPOLIA]: ZERO_ADDRESS
}

export const PIT_BREEDER: { [chainId: number]: string } = {
  [ChainId.MAINNET]: '0x38a75B033c2C3444Cb91D580645F76d042F98EdA',
  [ChainId.GÖRLI]: ZERO_ADDRESS,
  [ChainId.SEPOLIA]: ZERO_ADDRESS
}

export const PIT: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET as number, ZERO_ADDRESS, 18, 'xVIPER', 'ViperPit'),
  [ChainId.GÖRLI]: new Token(ChainId.GÖRLI as number, ZERO_ADDRESS, 18, 'xVIPER', 'ViperPit'),
  [ChainId.SEPOLIA]: new Token(ChainId.SEPOLIA as number, ZERO_ADDRESS, 18, 'xVIPER', 'ViperPit')
}

export const COMMON_CONTRACT_NAMES: { [address: string]: string } = {
  [UNI[ChainId.MAINNET].address]: 'UNI',
  [UNI[ChainId.GÖRLI].address]: 'UNI',
  [UNI[ChainId.SEPOLIA].address]: 'UNI',
  [UNI[ChainId.BSC_MAINNET].address]: 'UNI',
  [UNI[ChainId.BSC_TESTNET].address]: 'UNI',
  [UNI[ChainId.HECO_MAINNET].address]: 'UNI',
  [UNI[ChainId.HECO_TESTNET].address]: 'UNI',

  [GOVERNANCE_ADDRESS]: 'Governance',
  [TIMELOCK_ADDRESS]: 'Timelock'
}

const WETH_ONLY: ChainTokenList = Object.fromEntries(
  Object.entries(WETH)
    .map(([key, value]) => [key, [value]])
    .filter(Boolean)
)

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [
    ...WETH_ONLY[ChainId.MAINNET],
    DAI[ChainId.MAINNET],
    USDC[ChainId.MAINNET],
    USDT[ChainId.MAINNET],
    WBTC[ChainId.MAINNET]
  ],

  [ChainId.BSC_MAINNET]: [...WETH_ONLY[ChainId.BSC_MAINNET], USDC[ChainId.BSC_MAINNET]],
  [ChainId.BSC_TESTNET]: [...WETH_ONLY[ChainId.BSC_TESTNET], USDC[ChainId.BSC_TESTNET]],

  [ChainId.HECO_MAINNET]: [...WETH_ONLY[ChainId.HECO_MAINNET], USDC[ChainId.HECO_MAINNET]],
  [ChainId.HECO_TESTNET]: [...WETH_ONLY[ChainId.HECO_TESTNET], USDC[ChainId.HECO_TESTNET]]
}

export const ADDITIONAL_BASES: { [chainId: number]: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {
    '0xA948E86885e12Fb09AfEF8C52142EBDbDf73cD18': [
      new Token(ChainId.MAINNET as number, '0xA948E86885e12Fb09AfEF8C52142EBDbDf73cD18', 18, 'UNI', 'Uniswap')
    ],
    '0x561a4717537ff4AF5c687328c0f7E90a319705C0': [
      new Token(ChainId.MAINNET as number, '0x561a4717537ff4AF5c687328c0f7E90a319705C0', 18, 'UNI', 'Uniswap')
    ],
    [FEI.address]: [TRIBE],
    [TRIBE.address]: [FEI],
    [FRAX.address]: [FXS],
    [FXS.address]: [FRAX],
    [WBTC[ChainId.MAINNET].address]: [renBTC],
    [renBTC.address]: [WBTC[ChainId.MAINNET]]
  },

  [ChainId.BSC_MAINNET]: {
    '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c': [UNI[ChainId.BSC_MAINNET]],
    [HOKK[ChainId.BSC_MAINNET].address]: [BUSD],
    [BUSD.address]: [HOKK[ChainId.BSC_MAINNET]]
  },

  [ChainId.HECO_MAINNET]: {
    '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f': [UNI[ChainId.HECO_MAINNET]]
  }
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId: number]: { [tokenAddress: string]: Token[] } } = {
  /*
    [ChainId.MAINNET]: {
      [AMPL.address]: [DAI, WETH[ChainId.MAINNET]]
    },
  */
  [ChainId.BSC_MAINNET]: {},

  [ChainId.HECO_MAINNET]: {}
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [
    ...WETH_ONLY[ChainId.MAINNET],
    DAI[ChainId.MAINNET],
    USDC[ChainId.MAINNET],
    USDT[ChainId.MAINNET],
    WBTC[ChainId.MAINNET]
  ]
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [
    ...WETH_ONLY[ChainId.MAINNET],
    DAI[ChainId.MAINNET],
    USDC[ChainId.MAINNET],
    USDT[ChainId.MAINNET],
    WBTC[ChainId.MAINNET]
  ]
}

export const PINNED_PAIRS: { readonly [chainId: number]: [Token, Token][] } = {
  [ChainId.MAINNET]: [
    [
      new Token(ChainId.MAINNET as number, '0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643', 8, 'cDAI', 'Compound Dai'),
      new Token(
        ChainId.MAINNET as number,
        '0x39AA39c021dfbaE8faC545936693aC917d5E7563',
        8,
        'cUSDC',
        'Compound USD Coin'
      )
    ],
    [USDC[ChainId.MAINNET], USDT[ChainId.MAINNET]],
    [DAI[ChainId.MAINNET], USDT[ChainId.MAINNET]]
  ]
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 400
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// used for rewards deadlines
export const BIG_INT_SECONDS_IN_WEEK = JSBI.BigInt(60 * 60 * 24 * 7)

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), JSBI.BigInt(10000))

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

// SDN OFAC addresses
export const BLOCKED_ADDRESSES: string[] = [
  '0x7F367cC41522cE07553e823bf3be79A889DEbe1B',
  '0xd882cFc20F52f2599D84b8e8D58C7FB62cfE344b',
  '0x901bb9583b24D97e995513C6778dc6888AB6870e',
  '0xA7e5d5A720f06526557c513402f2e6B5fA20b008',
  '0x8576aCC5C05D6Ce88f4e49bf65BdF0C62F91353C'
]

export enum BridgeChain {
  ETH,
  BSC,
  HECO
}

export enum BridgeToken {
  HOKK
}

export interface BridgeXSupportChain {
  from: ChainId
  to: ChainId
}

export interface BridgeXToken {
  symbol: string
  name: string
  logoURI: string
  tokens: Token[]
  supportChains: BridgeXSupportChain[]
}

export interface TransactionType {
  _id: string
  orderID: number
  orderIDVotes: Array<string>
  detectionTime: Date
  hash: string
  endHash: string
  blockNumber: number
  signedCount: number
  signers: Array<string>
  startChain: string
  startChainIndex: number
  endChain: string
  sender: string
  recipient: string
  destination: string
  amount: BigNumber
  confirmed: boolean
  moniker: string
}

export interface PeriodType {
  name: string
  value: number
}

export const depositPeriods = [
  {
    name: '3 months',
    value: 3
  },
  {
    name: '6 months',
    value: 6
  },
  {
    name: '9 months',
    value: 9
  },
  {
    name: '12 months',
    value: 12
  }
]
