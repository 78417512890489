import { Contract } from '@ethersproject/contracts'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { BigNumber } from '@ethersproject/bignumber'
import { abi as IUniswapV2Router02ABI } from '@uniswap/v2-periphery/build/IUniswapV2Router02.json'
import { BRIDGE_X_ADDRESS, HOKK, ROUTER_ADDRESS, USDC, VALIDATOR_URL } from '../constants'
import { JSBI, Percent, CurrencyAmount, Currency, ETHER, Pair, Token, TokenAmount, ChainId } from 'sdk'
import { TokenAddressMap } from '../state/lists/hooks'
import { CHAIN_INFO } from 'constants/chains'
import axios from 'axios'
import { HOKK_BRIDGE_ABI } from 'constants/abis/hokk_bridge'
import { HOKK_PREMIUM_ABI, HOKK_PREMIUM_ADDRESS } from 'constants/abis/hokkpremium'
import { HOKKNFT_IMAGE } from 'constants/index'
import { BRIDGE_X_ABI } from 'constants/abis/bridge_x'

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

export function getEtherscanName(chainId: number): string {
  let title = ''

  switch (chainId) {
    case ChainId.BSC_MAINNET:
    case ChainId.BSC_TESTNET:
      title = 'Bscscan'
      break
    case ChainId.HECO_MAINNET:
    case ChainId.HECO_TESTNET:
      title = 'Hecoinfo'
      break
    default:
      title = 'Etherscan'
      break
  }

  return title
}

export function getEtherscanLink(
  chainId: number,
  data: string,
  type: 'transaction' | 'token' | 'address' | 'block'
): string {
  if (!CHAIN_INFO[chainId]) {
    return ''
  }
  const prefix = CHAIN_INFO[chainId].explorer

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`
    }
    case 'token': {
      return `${prefix}/token/${data}`
    }
    case 'block': {
      return `${prefix}/block/${data}`
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`
    }
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

export function calculateSlippageAmount(value: CurrencyAmount, slippage: number): [JSBI, JSBI] {
  if (slippage < 0 || slippage > 10000) {
    throw Error(`Unexpected slippage value: ${slippage}`)
  }
  return [
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 - slippage)), JSBI.BigInt(10000)),
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 + slippage)), JSBI.BigInt(10000))
  ]
}

// account is not optional
export function getSigner(provider: Web3Provider, account: string): JsonRpcSigner {
  return provider.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(provider: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(provider, account) : provider
}

// account is optional
export function getContract(address: string, ABI: any, provider: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(provider, account) as any)
}

// account is optional
export function getRouterContract(chainId: number, provider: Web3Provider, account?: string): Contract {
  return getContract(ROUTER_ADDRESS[chainId], IUniswapV2Router02ABI, provider, account)
}

export function getBridgeContract(chainId: ChainId, provider: Web3Provider, account: string): Contract {
  return getContract(HOKK[chainId].address, HOKK_BRIDGE_ABI, provider, account)
}

export function getBridgeXContract(chainId: ChainId, provider: Web3Provider, account: string): Contract {
  return getContract(BRIDGE_X_ADDRESS[chainId], BRIDGE_X_ABI, provider, account)
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function isTokenOnList(defaultTokens: TokenAddressMap, currency?: Currency): boolean {
  if (currency === ETHER) return true
  return Boolean(currency instanceof Token && defaultTokens[currency.chainId]?.[currency.address])
}

export function trimNativeSymbol(chainId: ChainId | undefined, currency: Currency | undefined) {
  if (!currency) {
    return ''
  }

  if (!chainId) {
    return currency.symbol
  }

  return currency === ETHER && CHAIN_INFO[chainId] ? CHAIN_INFO[chainId].nativeCurrency.symbol : currency.symbol
}

export function getNativeSymbol(chainId: ChainId | undefined) {
  if (!chainId) {
    return 'ETH'
  }

  return CHAIN_INFO[chainId].nativeCurrency.symbol
}

export async function getBridgeObject() {
  const { data } = await axios.get<any>(VALIDATOR_URL)
  console.log(data)
  let ips = data['IPs']
  let monikers = data['monikers']

  if (ips && monikers) {
    let idx = Math.floor(Math.random() * ips.length)

    return {
      url: ips[idx],
      moniker: monikers[idx]
    }
  } else {
    return undefined
  }
}

export function getPremiumContract(_: number, provider: Web3Provider, account?: string): Contract {
  return getContract(HOKK_PREMIUM_ADDRESS, HOKK_PREMIUM_ABI, provider, account)
}

// try to parse a user entered amount for a given token
export function tryParseEth(value: BigNumber | undefined): string | undefined {
  if (!value) {
    return undefined
  }

  try {
    let amount = CurrencyAmount.ether(JSBI.BigInt(value))
    return amount.toExact()
  } catch (error) {
    console.log(error)
  }

  return undefined
}

// try to parse a user entered amount for a given token
export function tryParseUsdc(value: BigNumber | undefined): string | undefined {
  if (!value) {
    return undefined
  }

  try {
    const amount = new TokenAmount(USDC[ChainId.MAINNET], JSBI.BigInt(value))
    return amount.toExact()
  } catch (error) {
    console.log(error)
  }

  return undefined
}

export const getTokenId = (nftId: string) => {
  var s = nftId + ''
  while (s.length < 4) s = '0' + s
  return s
}

export const getTokenImage = (tokenId: string | undefined) => {
  return tokenId ? HOKKNFT_IMAGE + getTokenId(tokenId) + '.png' : ''
}

export const getTokenName = (tokenId: string | undefined) => {
  return tokenId ? 'HOKK #' + getTokenId(tokenId) : ''
}

export const getTokenAmount = (isEth: boolean, amount: BigNumber | undefined) => {
  return isEth ? tryParseEth(amount) : tryParseUsdc(amount)
}

export const getAPYText = (APY: number | undefined) => {
  return ((APY ?? 0) * 100).toFixed(2)
}
