import { Connector } from '@web3-react/types'
import {
  coinbaseWalletConnection,
  gnosisSafeConnection,
  injectedConnection,
  networkConnection,
  walletConnectConnection
} from 'connection'
import { ALL_SUPPORTED_CHAIN_IDS, CHAIN_INFO } from 'constants/chains'
import { ChainId } from 'sdk'

export function isChainAllowed(connector: Connector, chainId: number) {
  switch (connector) {
    case injectedConnection.connector:
    case coinbaseWalletConnection.connector:
    case walletConnectConnection.connector:
    case networkConnection.connector:
    case gnosisSafeConnection.connector:
      return ALL_SUPPORTED_CHAIN_IDS.includes(chainId)
    default:
      return false
  }
}

export const switchChain = async (connector: Connector, chainId: ChainId) => {
  if (!isChainAllowed(connector, chainId)) {
    throw new Error(`Chain ${chainId} not supported for connector (${typeof connector})`)
  } else if (connector === walletConnectConnection.connector || connector === networkConnection.connector) {
    await connector.activate(chainId)
  } else {
    const info = CHAIN_INFO[chainId]
    await connector.activate({
      chainId,
      chainName: info.label,
      rpcUrls: info.rpcUrls,
      nativeCurrency: info.nativeCurrency,
      blockExplorerUrls: [info.explorer],
      iconUrls: [info.icon]
    })
  }
}
