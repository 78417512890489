import { useMemo } from 'react'
import { Token, Price, WETH, ChainId } from 'sdk'
import { useWeb3React } from '@web3-react/core'
import { usePair } from '../data/Reserves'

export default function useTokenWethPrice(token: Token | undefined): Price | undefined {
  const { chainId } = useWeb3React()
  const [, tokenWETHPair] = usePair(chainId && WETH[chainId] ? WETH[chainId] : undefined, token)

  return useMemo(() => {
    return token && chainId && tokenWETHPair ? tokenWETHPair.priceOf(token) : undefined
  }, [chainId, token, tokenWETHPair])
}
