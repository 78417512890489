import { ButtonLight } from 'components/Button'
import React from 'react'
import styled from 'styled-components'

import logo from '../../assets/images/logomain.png'
import imgBridge from '../../assets/images/sidebar/bridge.png'
import imgSwap from '../../assets/images/sidebar/exchange.png'
import imgFarm from '../../assets/images/sidebar/farms.png'
import imgSingleFarm from '../../assets/images/sidebar/single_farms.png'
import imgPremium from '../../assets/images/sidebar/premium.png'
import imgLiquidity from '../../assets/images/sidebar/liquidity.png'
import imgMint from '../../assets/images/sidebar/mint.png'
import imgMigration from '../../assets/images/sidebar/migration.png'
import { ReactComponent as LogoutIcon } from '../../assets/images/sidebar/logout.svg'
import { ReactComponent as CloseIcon } from '../../assets/images/sidebar/close.svg'
import { NavLink, useLocation } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import { useCloseModals, useModalOpen } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/actions'
import { useWindowSize } from 'hooks/useWindowSize'
import { ExternalLink, MEDIA_WIDTHS } from 'theme'
import { useEffect } from 'react'
import { ChainId } from 'sdk'

const Wrapper = styled.div`
  position: fixed;
  top: 88px;
  left: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  background: ${({ theme }) => theme.bg7};
  border-radius: 24px;
  padding: 30px 0px 36px 0px;
  width: 200px;
  min-height: calc(100vh - 120px);
  max-height: calc(100vh - 80px);
  overflow-y: auto;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 180px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    height:100%;
    padding: 24px;
    background: rgba(7, 8, 9, 0.62);
    backdrop-filter: blur(18px);
    border-radius: 0px;
    z-index: 99;
  `};
`

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  justify-content: space-between;
`};
`

const CloseButton = styled.div`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
    cursor: pointer;
  `};
`

const MenuWrapper = styled.div`
  flex: 1;
  padding-left: 20px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  padding-left:0px;
`};
`

const Logo = styled.img`
  width: 108px;
`

const MenuItem = styled(NavLink)`
  padding: 8px 12px;
  display: flex;
  gap: 12px;
  color: white;
  text-decoration: none;
  align-items: center;
  font-size: 12px;

  &.active {
    background: ${({ theme }) => theme.bg8};
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
  }

  &.active .menu-text {
    color: ${({ theme }) => theme.primary5};
  }

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 24px;

    &.active {
      background: inherit;
    }
  `};
`

const MenuIcon = styled.img`
  width: 32px;
  height: 32px;
`

const MenuText = styled.span`
  text-align: center;
  position: relative;
`

const LinkItem = styled(ExternalLink)`
  padding: 8px 12px;
  display: flex;
  gap: 12px;
  color: white;
  text-decoration: none;
  align-items: center;
  font-size: 12px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 24px;
  `};
`

const ButtonLogout = styled(ButtonLight)`
  border-radius: 5px;
  padding: 8px 15px;
  height: auto;
  font-size: 13.87px;
  font-family: poppinssemibold;
  border: 0 !important;
  margin: auto;

  &:hover .iconLogout {
    fill: white;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0;
  `};
`

export default function Sidebar() {
  const { width } = useWindowSize()
  const isMobile = width && width < MEDIA_WIDTHS.upToSmall
  const { pathname } = useLocation()

  const open = useModalOpen(ApplicationModal.SIDEBAR)
  const close = useCloseModals()

  useEffect(() => {
    close()
  }, [pathname])

  return open || !isMobile ? (
    <Wrapper>
      <LogoWrapper>
        <Logo src={logo} alt="logo" />
        <CloseButton onClick={close}>
          <CloseIcon />
        </CloseButton>
      </LogoWrapper>

      <MenuWrapper>
        <MenuItem to="/swap">
          <MenuIcon src={imgSwap} alt="Swap" />
          <MenuText className="menu-text">Exchange</MenuText>
        </MenuItem>

        <MenuItem
          to="/pool"
          isActive={(match, location) => {
            return (
              location.pathname.includes('/pool') ||
              location.pathname.includes('/create') ||
              location.pathname.includes('/add')
            )
          }}
        >
          <MenuIcon src={imgLiquidity} alt="imgLiquidity" />
          <MenuText className="menu-text">Liquidity</MenuText>
        </MenuItem>

        <MenuItem to="/dogpool">
          <MenuIcon src={imgFarm} alt="Farms" />
          <MenuText className="menu-text">Farms</MenuText>
        </MenuItem>

        <MenuItem to="/singleFarm">
          <MenuIcon src={imgSingleFarm} alt="Single Farms" />
          <MenuText className="menu-text">Single Farms</MenuText>
        </MenuItem>

        <MenuItem to="/bridge">
          <MenuIcon src={imgBridge} alt="Bridge" />
          <MenuText className="menu-text">Bridge</MenuText>
        </MenuItem>

        {
          /*
            <MenuItem to="/bridgex">
              <MenuIcon src={imgBridge} alt="imgBridge" />
              <MenuText className="menu-text">Bridge-X</MenuText>
            </MenuItem>
          */
        }

        <MenuItem
          to="/premium"
          isActive={(match, location) => {
            return (
              location.pathname.includes('/premium') ||
              location.pathname.includes('/deposit') ||
              location.pathname.includes('/my_funds')
            )
          }}
        >
          <MenuIcon src={imgPremium} alt="Premium" />
          <MenuText className="menu-text">Premium</MenuText>
        </MenuItem>

        {/* <MenuItem to="/lottery">
          <MenuIcon src={imgLottery} alt="Lottery" />
          <MenuText className='menu-text'>Lottery</MenuText>
        </MenuItem> */}

        <LinkItem href="https://nft.hokkfi.com/" target="_blank">
          <MenuIcon src={imgMint} alt="NFT Mint" />
          <MenuText className="menu-text">NFT Mint</MenuText>
        </LinkItem>

        <LinkItem href="https://hokkairdrop.hokkfi.com/" target="_blank">
          <MenuIcon src={imgMigration} alt="Migration" />
          <MenuText className="menu-text">Migration</MenuText>
        </LinkItem>
      </MenuWrapper>

      {/*
      <ButtonLogout onClick={deactivate}>
        <LogoutIcon className="iconLogout" fill="#4C6FFF" />
        Logout
      </ButtonLogout>
      */}
    </Wrapper>
  ) : (
    <></>
  )
}
