import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import Header from '../components/Header'
import Polling from '../components/Header/Polling'
import Popups from '../components/Popups'
import AddLiquidity from './AddLiquidity'
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity
} from './AddLiquidity/redirects'
import Earn from './Earn'
import Dogpool from './Dogpool'
import Manage from './Earn/Manage'
import SingleStakingManage from './Dogpool/SingleStakingManage'
import Pool from './Pool'
import PoolFinder from './PoolFinder'
import RemoveLiquidity from './RemoveLiquidity'
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
import Swap from './Swap'
import { RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'
import Vote from './Vote'
import VotePage from './Vote/VotePage'
import Sidebar from 'components/Sidebar'
import SingleFarm from './SingleFarm'
import Bridge from './Bridge/Home'
import BridgeHistory from './Bridge/History'
import MyFunds from './Premium/MyFunds'
import Deposit from './Premium/Deposit'
import Premium from './Premium/Home'
import Lottery from './Lottery'
import Governance from './Governance'
import BridgeX from './BridgeX'
import URLWarning from 'components/Header/URLWarning'

const AppWrapper = styled.div``

const BodyWrapper = styled.div`
  width: 100%;
  padding: 5.5rem 2rem 0;
  margin-bottom: 30px;
  overflow-x: hidden;
  z-index: 10;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 5rem 1rem 0;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 16px;
    margin-bottom: 80px;
  `};
`

const HomeWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
`

const SidebarWrapper = styled.div`
  z-index: 20;
  display: flex;
  position: fixed;
`

const MainWrapper = styled.div`
  flex: 1;
  margin-left: 14.2rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  margin-left: 13rem;;
`};
  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin-left: 0!important;
  `};
`

export default function App() {
  return (
    <Suspense fallback={null}>
      <AppWrapper>
        <URLWarning />
        <Header />
        <Sidebar />
        <BodyWrapper>
          <Popups />
          <Polling />
          <HomeWrapper>
            <MainWrapper>
              <Switch>
                <Route exact strict path="/swap" component={Swap} />
                <Route exact strict path="/singleFarm" component={SingleFarm} />
                <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
                <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
                <Route exact strict path="/find" component={PoolFinder} />
                <Route exact strict path="/pool" component={Pool} />
                {
                  /*
                  <Route exact strict path="/staking" component={Earn} />
                  */
                }
                <Route exact strict path="/dogpool" component={Dogpool} />
                <Route exact strict path="/vote" component={Vote} />
                <Route exact strict path="/create" component={RedirectToAddLiquidity} />
                <Route exact path="/add" component={AddLiquidity} />
                <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
                <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
                <Route exact path="/create" component={AddLiquidity} />
                <Route exact path="/create/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
                <Route exact path="/create/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
                <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
                <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
                <Route exact strict path="/staking/:currencyIdA/:currencyIdB" component={Manage} />
                <Route exact strict path="/staking/:currencyId" component={SingleStakingManage} />
                <Route exact strict path="/vote/:id" component={VotePage} />

                <Route exact strict path="/bridge" component={Bridge} />
                <Route exact strict path="/bridge_history" component={BridgeHistory} />

                {
                  /*
                    <Route exact strict path="/bridgeX" component={BridgeX} />
                  */
                }

                <Route exact strict path="/premium" component={Premium} />
                <Route exact strict path="/deposit" component={Deposit} />
                <Route exact strict path="/my_funds" component={MyFunds} />

                <Route exact strict path="/lottery" component={Lottery} />
                <Route exact strict path="/governance" component={Governance} />

                <Route component={RedirectPathToSwapOnly} />
              </Switch>
            </MainWrapper>
          </HomeWrapper>
        </BodyWrapper>
      </AppWrapper>
    </Suspense>
  )
}
