import { useMemo } from 'react'
import { SINGLE_STAKING_REWARDS_INFO } from '../constants/staking'
import { useWeb3React } from '@web3-react/core'

export default function useSingleStakingTokenAddresses(): string[] | undefined {
  const { chainId } = useWeb3React()
  return useMemo(() => {
    const pools = chainId
      ? SINGLE_STAKING_REWARDS_INFO[chainId]?.map(stakingRewardInfo =>
          stakingRewardInfo.token.address
        )
      : []

    return pools
  }, [chainId])
}
