import React, { useCallback, useState } from 'react'

import { JSBI } from 'sdk'
import { Link, RouteComponentProps } from 'react-router-dom'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { useCurrency } from '../../hooks/Tokens'
import { useWalletModalToggle } from '../../state/application/hooks'
import { useMasterBreederContract } from '../../hooks/useContract'
import { TYPE } from '../../theme'

import { ButtonWhite } from '../../components/Button'
import SingleStakingModal from '../../components/earn/SingleStakingModal'
import { useSingleStakingInfo, useGetETHReward } from '../../state/stake/hooks'
import SingleUnstakingModal from '../../components/earn/SingleUnstakingModal'
import SingleClaimRewardModal from '../../components/earn/SingleClaimRewardModal'
import { useTokenBalance } from '../../state/wallet/hooks'
import { useWeb3React } from '@web3-react/core'
import { useColor } from '../../hooks/useColor'
import { CountUp } from 'use-count-up'
import Withdrawmodals from './Withdrawmodals'
import iconStar from '../../assets/images/iconStar.svg'

import { wrappedCurrency } from '../../utils/wrappedCurrency'
import { useBalanceOf } from '../../data/BalanceOf'
import usePrevious from '../../hooks/usePrevious'
import { BIG_INT_ZERO } from '../../constants'
import useGovernanceToken from '../../hooks/useGovernanceToken'
import { ArrowLeft } from 'react-feather'

export default function SingleStakingManage({
  match: {
    params: { currencyId }
  }
}: RouteComponentProps<{ currencyId: string }>) {
  const { account, chainId } = useWeb3React()
  const masterBreederContract = useMasterBreederContract()
  const govToken = useGovernanceToken()

  // toggle for staking modal and unstaking modal
  const [showStakingModal, setShowStakingModal] = useState(false)
  const [showUnstakingModal, setShowUnstakingModal] = useState(false)
  const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)

  const currency = useCurrency(currencyId)
  const tokenA = wrappedCurrency(currency ?? undefined, chainId)

  // const [, stakingTokenPair] = usePair(tokenA, tokenB)
  const stakingInfo = useSingleStakingInfo(undefined, [currencyId])?.[0]

  // detect existing unstaked LP position to show add button if none found
  const userLiquidityUnstaked = useTokenBalance(account ?? undefined, stakingInfo?.stakedAmount?.token)
  const showAddLiquidityButton = Boolean(stakingInfo?.stakedAmount?.equalTo('0') && userLiquidityUnstaked?.equalTo('0'))

  // const token = currencyA === ETHER ? tokenB : tokenA
  // const WETH = currencyA === ETHER ? tokenA : tokenB
  const token = tokenA

  // get WETH value of staked LP tokens
  const valueOfTotalStakedAmount = useBalanceOf(stakingInfo?.stakedAmount?.token, masterBreederContract?.address)
  // let valueOfTotalStakedAmountInWETH: TokenAmount | undefined
  // if (totalSupplyOfStakingToken && stakingTokenPair && stakingInfo && WETH) {
  //   // take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
  //   valueOfTotalStakedAmountInWETH = new TokenAmount(
  //     WETH,
  //     JSBI.divide(
  //       JSBI.multiply(
  //         JSBI.multiply(stakingInfo.totalStakedAmount.raw, stakingTokenPair.reserveOf(WETH).raw),
  //         JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
  //       ),
  //       totalSupplyOfStakingToken.raw
  //     )
  //   )
  // }

  const countUpAmount = stakingInfo?.earnedAmount?.toFixed(6) ?? '0'
  const countUpAmountPrevious = usePrevious(countUpAmount) ?? '0'

  // get the USD value of staked WETH
  // const USDPrice = useUSDCPrice(WETH)
  // const valueOfTotalStakedAmountInUSDC =
  //   valueOfTotalStakedAmountInWETH && USDPrice?.quote(valueOfTotalStakedAmountInWETH)

  const toggleWalletModal = useWalletModalToggle()

  const handleDepositClick = useCallback(() => {
    if (account) {
      setShowStakingModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  return (
    <>
      {stakingInfo && (
        <>
          <SingleStakingModal
            isOpen={showStakingModal}
            onDismiss={() => setShowStakingModal(false)}
            stakingInfo={stakingInfo}
            userLiquidityUnstaked={userLiquidityUnstaked}
          />
          <SingleUnstakingModal
            isOpen={showUnstakingModal}
            onDismiss={() => setShowUnstakingModal(false)}
            stakingInfo={stakingInfo}
          />
          <SingleClaimRewardModal
            isOpen={showClaimRewardModal}
            onDismiss={() => setShowClaimRewardModal(false)}
            stakingInfo={stakingInfo}
          />
        </>
      )}
      <div>
        <div className="cardWrap treatMining">
          <h2 className="titleHead d-flex">
            <Link to="/singleFarm">
              <ArrowLeft size={'20px'} color="#FFFFFF" />
            </Link>
            <span> {currency?.symbol} Staking</span>
            <ul className="iconImg mg-l-auto">
              <DoubleCurrencyLogo currency0={currency ?? undefined} currency1={undefined} size={24} />
            </ul>
          </h2>
          <div className="rows">
            <div className="colHalf">
              <div className="cardIn">
                <div className="textWrap">
                  <h3 className="whtClr liteHead">Total staked {currency?.symbol}</h3>
                  <h2 className="d-flex align-item-center whtClr strongHead iconTx">
                    <DoubleCurrencyLogo currency0={currency ?? undefined} currency1={undefined} size={24} />
                    {valueOfTotalStakedAmount
                      ? `${valueOfTotalStakedAmount?.toSignificant(4, { groupSeparator: ',' })} ${currency?.symbol}`
                      : '-'}
                  </h2>
                </div>
              </div>
            </div>
            <div className="colHalf">
              <div className="cardIn">
                <div className="textWrap">
                  <h3 className="whtClr liteHead">Emission Rate</h3>
                  <h2 className="d-flex align-item-center whtClr iconTx strongHead">
                    {stakingInfo
                      ? stakingInfo.active
                        ? `${stakingInfo.poolRewardsPerBlock.toSignificant(3, { groupSeparator: ',' })} 
                    ${govToken?.symbol} / block`
                        : `0 ${govToken?.symbol} / block`
                      : '-'}
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div className="rows liquBox">
            <div className="colFull">
              <div className="cardIn depositsWrap blueGrade tokenWrap textLg">
                <h3 className="fontWeightBold whtClr">Your Staked {currency?.symbol}</h3>
                <div className="d-flex widthFull">
                  <h2 className="whtClr widthFull fontWeightBold align-item-center d-flex">
                    <span>{stakingInfo?.stakedAmount?.toSignificant(6) ?? '-'}</span>{' '}
                    <small className="mg-l-auto">{currency?.symbol}</small>
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div className="rows liquBox">
            <div className="colFull">
              <div className="resutlWrap">
                <h3 className="grayClr widthFull fontWeightBold mt-4">
                  <span>Your unclaimed TREATS</span>

                  {stakingInfo?.earnedAmount && JSBI.notEqual(BIG_INT_ZERO, stakingInfo?.earnedAmount?.raw) && (
                    <ButtonWhite
                      padding="8px"
                      borderRadius="8px"
                      width="fit-content"
                      onClick={() => setShowClaimRewardModal(true)}
                    >
                      Claim
                    </ButtonWhite>
                  )}
                </h3>
                <h2 className="whtClr fontWeightBold">
                  <CountUp
                    key={countUpAmount}
                    isCounting
                    decimalPlaces={4}
                    start={parseFloat(countUpAmountPrevious)}
                    end={parseFloat(countUpAmount)}
                    thousandsSeparator={','}
                    duration={1}
                  />
                </h2>

                <hr className="dividerHr" />
                <p className="d-flex iconTx grayClr font18">
                  <img src={iconStar} alt="star icon" className="mtm4" /> When you withdraw, the contract will
                  automagically claim TREATS on your behalf!
                </p>
                <div className="withdrwBtn mt15">
                  <Withdrawmodals
                    showAddLiquidityButton={showAddLiquidityButton}
                    stakingInfo={stakingInfo}
                    currency={currency}
                    userLiquidityUnstaked={userLiquidityUnstaked}
                  />
                </div>
              </div>
            </div>
          </div>

          {!userLiquidityUnstaked ? null : userLiquidityUnstaked.equalTo('0') ? null : !stakingInfo?.active ? null : (
            <TYPE.main style={{ marginTop: '15px' }}>
              {userLiquidityUnstaked.toSignificant(6)} {currency?.symbol} available
            </TYPE.main>
          )}
        </div>
      </div>
    </>
  )
}
