import ethLogoUrl from 'assets/images/ethereum-logo.png'
import bscLogoUrl from 'assets/images/binance-logo.png'
import hecoLogoUrl from 'assets/images/heco-logo.png'
import { ChainId } from 'sdk'
import { RPC_URLS } from './networks'

export interface L1ChainInfo {
  readonly explorer: string
  readonly label: string
  readonly logoUrl?: string
  readonly rpcUrls?: string[]
  readonly icon: string
  readonly nativeCurrency: {
    name: string
    symbol: string
    decimals: number
  }
}

export type ChainInfo = { readonly [chainId: number]: L1ChainInfo }

export const CHAIN_INFO: ChainInfo = {
  [ChainId.MAINNET]: {
    explorer: 'https://etherscan.io',
    label: 'Ethereum',
    logoUrl: ethLogoUrl,
    rpcUrls: [RPC_URLS[ChainId.MAINNET]],
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    icon: 'https://assets-cdn.trustwallet.com/blockchains/ethereum/info/logo.png'
  },
  [ChainId.GÖRLI]: {
    explorer: 'https://goerli.etherscan.io',
    label: 'Görli',
    logoUrl: ethLogoUrl,
    rpcUrls: [RPC_URLS[ChainId.GÖRLI]],
    nativeCurrency: { name: 'Görli ETH', symbol: 'gorETH', decimals: 18 },
    icon: 'https://assets-cdn.trustwallet.com/blockchains/ethereum/info/logo.png'
  },
  [ChainId.SEPOLIA]: {
    explorer: 'https://sepolia.etherscan.io',
    label: 'Sepolia',
    logoUrl: ethLogoUrl,
    rpcUrls: [RPC_URLS[ChainId.SEPOLIA]],
    nativeCurrency: { name: 'Sepolia ETH', symbol: 'sepETH', decimals: 18 },
    icon: 'https://assets-cdn.trustwallet.com/blockchains/ethereum/info/logo.png'
  },

  [ChainId.BSC_MAINNET]: {
    explorer: 'https://bscscan.com',
    label: 'BSC',
    logoUrl: bscLogoUrl,
    rpcUrls: [RPC_URLS[ChainId.BSC_MAINNET]],
    nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
    icon: 'https://assets-cdn.trustwallet.com/blockchains/binance/info/logo.png'
  },
  [ChainId.BSC_TESTNET]: {
    explorer: 'https://testnet.bscscan.com',
    label: 'BSC Testnet',
    logoUrl: bscLogoUrl,
    rpcUrls: [RPC_URLS[ChainId.BSC_TESTNET]],
    nativeCurrency: { name: 'Testnet BNB', symbol: 'tBNB', decimals: 18 },
    icon: 'https://assets-cdn.trustwallet.com/blockchains/binance/info/logo.png'
  },

  [ChainId.HECO_MAINNET]: {
    explorer: 'https://hecoinfo.com',
    label: 'HECO',
    logoUrl: hecoLogoUrl,
    rpcUrls: [RPC_URLS[ChainId.HECO_MAINNET]],
    nativeCurrency: { name: 'HT', symbol: 'HT', decimals: 18 },
    icon: 'https://assets-cdn.trustwallet.com/blockchains/heco/info/logo.png'
  },
  [ChainId.HECO_TESTNET]: {
    explorer: 'https://testnet.hecoinfo.com',
    label: 'HECO Testnet',
    logoUrl: hecoLogoUrl,
    rpcUrls: [RPC_URLS[ChainId.HECO_TESTNET]],
    nativeCurrency: { name: 'Testnet HT', symbol: 'tHT', decimals: 18 },
    icon: 'https://assets-cdn.trustwallet.com/blockchains/heco/info/logo.png'
  }
}

export const CHAIN_IDS_TO_NAMES = {
  [ChainId.MAINNET]: 'mainnet',
  [ChainId.GÖRLI]: 'goerli',
  [ChainId.SEPOLIA]: 'sepolia',
  [ChainId.BSC_MAINNET]: 'bsc',
  [ChainId.BSC_TESTNET]: 'bsc_test',
  [ChainId.HECO_MAINNET]: 'heco',
  [ChainId.HECO_TESTNET]: 'heco_test'
}

export const ALL_SUPPORTED_CHAIN_IDS: ChainId[] = Object.values(ChainId).filter(
  id => typeof id === 'number'
) as ChainId[]
