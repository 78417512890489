import { JsonRpcProvider } from '@ethersproject/providers'
import { ChainId } from 'sdk'

const INFURA_KEY = process.env.REACT_APP_INFURA_KEY
if (typeof INFURA_KEY === 'undefined') {
  throw new Error(`REACT_APP_INFURA_KEY must be a defined environment variable`)
}

export const MAINNET_PROVIDER = new JsonRpcProvider(`https://mainnet.infura.io/v3/${INFURA_KEY}`)

/**
 * These are the network URLs used by the interface when there is not another available source of chain data
 */
export const RPC_URLS: { [key in ChainId]: string } = {
  [ChainId.MAINNET]: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
  [ChainId.GÖRLI]: `https://goerli.infura.io/v3/${INFURA_KEY}`,
  [ChainId.SEPOLIA]: `https://sepolia.infura.io/v3/${INFURA_KEY}`,
  [ChainId.BSC_MAINNET]: `https://bsc-dataseed.binance.org`,
  [ChainId.BSC_TESTNET]: `https://data-seed-prebsc-1-s1.binance.org:8545/`,
  [ChainId.HECO_MAINNET]: `https://http-mainnet.hecochain.com`,
  [ChainId.HECO_TESTNET]: `https://http-testnet.hecochain.com`
}