import { ChainId, Currency, ETHER, Token } from 'sdk'

import React, { useMemo } from 'react'
import styled from 'styled-components'

import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import Logo from '../Logo'

import EthereumLogo from '../../assets/images/ethereum-logo.png'
import BinanceLogo from '../../assets/images/binance-logo.png'
import HecoLogo from '../../assets/images/heco-logo.png'
import { isBSC, isETH, isHECO } from 'sdk/utils'
import { useWeb3React } from '@web3-react/core'

export const getTokenLogoURL = (chainId: ChainId, address: string) => {
  let path = '';
  if (address === '0xe87e15B9c7d989474Cb6d8c56b3DB4eFAD5b21E8'
    || address === '0x4EbF49cc2d2dd029e5CFDf868316385DfFd94c6A') {
    path = '/images/logo.png';
  }
  else {
    let chain = "";
    if (chainId === ChainId.MAINNET) {
      chain = 'etheruem';
    }
    else if (chainId === ChainId.BSC_MAINNET) {
      chain = 'binance';
    }
    else if (chainId === ChainId.HECO_MAINNET) {
      chain = 'heco';
    }

    if (chain != '') {
      path = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${chain}/assets/${address}/logo.png`
    }

  }

  return path;
}

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const StyledLogo = styled(Logo) <{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  background-color: ${({ theme }) => theme.white};
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const { chainId } = useWeb3React();
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)

  const srcs: string[] = useMemo(() => {
    if (currency === ETHER)
      return []

    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, getTokenLogoURL(currency.chainId, currency.address)]
      }
      return [getTokenLogoURL(currency.chainId, currency.address)]
    }
    return []
  }, [currency, uriLocations])

  if (chainId && currency === ETHER) {

    if (isETH(chainId)) {
      return <StyledEthereumLogo src={EthereumLogo} size={size} style={style} />
    }
    else if (isBSC(chainId)) {
      return <StyledEthereumLogo src={BinanceLogo} size={size} style={style} />
    }
    else if (isHECO(chainId)) {
      return <StyledEthereumLogo src={HecoLogo} size={size} style={style} />
    }

  }

  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
}
