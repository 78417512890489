import { Token, TokenAmount, JSBI } from 'sdk'

export default function calculateTotalSingleStakedAmount(
  baseToken: Token,
  totalStakedAmount: TokenAmount,
): TokenAmount {
  // take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
  if (baseToken.decimals === 9) {
    return new TokenAmount(
      baseToken,
      JSBI.divide(totalStakedAmount.raw, JSBI.BigInt(10**9))
    )
  }
  return new TokenAmount(
    baseToken,
    totalStakedAmount.raw,
  )
}
