import styled from 'styled-components'
import React, { useState } from 'react'
import { BigNumber } from 'ethers';
import { TransactionResponse } from '@ethersproject/abstract-provider';
import { BigText, MediumText, TYPE } from 'theme'
import { Flex, Text } from 'rebass'

import { useWeb3React } from '@web3-react/core';
import { useToggleModal, useWalletModalToggle } from 'state/application/hooks';
import { useIsTransactionPending, useTransactionAdder } from 'state/transactions/hooks';
import { useAaveAPY, /*useCurrentAPY,*/ useNftYields, useUserData } from 'hooks/useContract';
import { calculateGasMargin, getAPYText, getPremiumContract, getTokenAmount, getTokenImage, getTokenName, tryParseEth, tryParseUsdc } from 'utils';

import { PremiumMyFundsTabs } from 'components/NavigationTabs';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import clip5Img from '../../assets/images/clip5.png'
import nftIcon from '../../assets/images/nft-icon.png'
import { useUserAsset } from 'state/user/hooks';
import { ButtonPrimary } from 'components/Button';
import { ApplicationModal } from 'state/application/actions';
import { BodyWrapper } from 'pages/AppBody';
import NftSearchModal from 'components/NftSearchModal';

import { NotAvailableChain } from '../../theme'
import { ChainId } from 'sdk'

const Wrapper = styled(BodyWrapper)`
    padding: 2rem;
    min-height: calc(100vh - 120px);
    
    ${({ theme }) => theme.mediaWidth.upToSmall`
        padding: 1rem;
    `};
`

const InnerWrapper = styled.div`
    padding: 1rem;
`

const Title = styled(BigText)`

  padding-bottom: 0px;
  
  ${({ theme }) => theme.mediaWidth.upToSmall`
    text-align: center;
  `};

`

const Subtitle = styled(MediumText)`

  padding-top: 12px;
  padding-bottom: 0px;

`

const FlexLine = styled.div`
  display: flex;
  
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: block;
  `};

`

const TitleLine = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${({ theme }) => theme.mediaWidth.upToSmall`
      flex-direction: column;
      gap: 18px;
      align-items: center;
    `};
`

const TextLine = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 18px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    text-align: center;
    max-width: 400px;
    margin: 0 auto;
  `};
`

const FundLine = styled.div`
  background: linear-gradient(98deg, rgba(45, 171, 229, 0.5) -16%, rgba(149, 40, 255, 0.5) 100%);
  border-radius: 12px;
  padding: 16px 24px;
  text-align: left;
`

const ImgLine = styled.div`
  flex: 1;
  text-align: center;
  margin: 0 auto;

  & img {
    width: 300px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    & img {
      margin-top: 32px;
      width: 200px;
    }
  `};
`

const NftItems = styled.div`
  display: block;
  user-select: none;
  margin: 16px 0px;
  width: calc(100vw - 500px);
  
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: calc(100vw - 400px);
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: calc(100vw - 100px);
  `};
`

const NftEmpty = styled.div`
  width: 100%;

`

const NftItem = styled.div`
  position: relative;
  user-select: none;
  width: 260px;
`

const NftDrop = styled.div`
    width: 240px;
    height: 240px;
    background: linear-gradient(180deg, rgba(144, 83, 189, 0) 0%, #9053BD 100%);
    border-radius: 16px;
    backdrop-filter: blur(40px);
    opacity: 0.2;
    padding: 24px;
    margin-bottom: 12px;
`

const NftImg = styled.div<{ src: string }>`
    position: absolute;
    top: 20px;
    left: 20px;
    width: 200px;
    height: 200px;
    background-image: ${(item) => `url(${item.src})`};
    background-size: contain;
`

const NftText = styled(Text)`
    text-align: center;
`

const NftYield = styled(Text)`
    font-size: 14px;
    text-align: center;
`

const NftClaimButton = styled(ButtonPrimary)`
    width: 120px;
    margin: 8px auto;
    box-shadow: none;
    padding: 8px 0px;
`

const FundsDivider = styled.div`
    width: 100%;
    height: 1px;
    background: rgb(224 228 251 / 60%);
    margin: 32px 0px;
`

const ButtonSearch = styled(ButtonPrimary)`
    width: 160px;
    height: 48px;
    justify-content: flex-start;
    padding: 8px 16px;
    gap: 12px;
    margin: 12px 0px;

    & img {
      width: 32px;
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
      width: 140px;
    `};

`


export default function MyFunds() {

    const { chainId, account, provider } = useWeb3React();
    const toggleWalletModal = useWalletModalToggle();
    const toggleSearchModal = useToggleModal(ApplicationModal.NFT_SEARCH);

    const addTransaction = useTransactionAdder();
    const { userEthYield, userEthDeposit, userUsdcYield, userUsdcDeposit, userNftIds } = useUserData(account);
    const [isEth] = useUserAsset()
    // const APY = useCurrentAPY(isEth);
    const { ethAPY, usdcAPY } = useAaveAPY();
    const userNftYields = useNftYields(isEth, userNftIds);

    const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
    const [txHash, setTxHash] = useState<string>('')
    const [errorMessage, setErrorMessage] = useState<string>('')
    const isPending = useIsTransactionPending(txHash);
    const coinName = isEth ? 'ETH' : 'USDC';

    const userDeposit = (isEth ? tryParseEth(userEthDeposit) : tryParseUsdc(userUsdcDeposit));
    const userYield = (isEth ? tryParseEth(userEthYield) : tryParseUsdc(userUsdcYield));

    const handleClaim = () => {
        if (!account) {
            toggleWalletModal();
            return;
        }

        claim();
    }

    const handleClaimNFT = (tokenId: string) => {
        if (!account) {
            toggleWalletModal();
            return;
        }

        claimNFT(tokenId);
    }

    async function claimNFT(tokenId: string) {
        if (!chainId || !provider || !account) return
        const contract = getPremiumContract(chainId, provider, account)

        let estimate,
            method: (...args: any) => Promise<TransactionResponse>,
            args: Array<string | string[] | number>,
            value: BigNumber | null;

        estimate = isEth ? contract.estimateGas.claimNFTETHYield : contract.estimateGas.claimNFTUSDCYield
        method = isEth ? contract.claimNFTETHYield : contract.claimNFTUSDCYield
        args = [
            [
                tokenId
            ]
        ]

        setAttemptingTxn(true)
        await estimate(...args, {})
            .then(estimatedGasLimit =>
                method(...args, {
                    ...(value ? { value } : {}),
                    gasLimit: calculateGasMargin(estimatedGasLimit)
                }).then(response => {
                    setAttemptingTxn(false)

                    addTransaction(response, {
                        summary:
                            'Claim has been successed.'
                    })
                    setTxHash(response.hash)
                    setErrorMessage('');
                })
            )
            .catch(error => {
                setAttemptingTxn(false)
                // we only care if the error is something _other_ than the user rejected the tx
                if (error?.code !== 4001) {
                    if (error.data) {
                        setErrorMessage(error.data.message);
                    }
                    else {
                        setErrorMessage(error.message);
                    }
                }
                else {
                    setErrorMessage('User rejected transaction.');
                }
            })
    }


    async function claim() {
        if (!chainId || !provider || !account) return
        const contract = getPremiumContract(chainId, provider, account)

        let estimate,
            method: (...args: any) => Promise<TransactionResponse>,
            args: Array<string | string[] | number>,
            value: BigNumber | null;

        estimate = isEth ? contract.estimateGas.claimETHYield : contract.estimateGas.claimUSDCYield
        method = isEth ? contract.claimETHYield : contract.claimUSDCYield
        args = []

        setAttemptingTxn(true)
        await estimate(...args, {})
            .then(estimatedGasLimit =>
                method(...args, {
                    ...(value ? { value } : {}),
                    gasLimit: calculateGasMargin(estimatedGasLimit)
                }).then(response => {
                    setAttemptingTxn(false)

                    addTransaction(response, {
                        summary:
                            'Claim has been successed.'
                    })
                    setTxHash(response.hash)
                    setErrorMessage('');
                })
            )
            .catch(error => {
                setAttemptingTxn(false)
                // we only care if the error is something _other_ than the user rejected the tx
                if (error?.code !== 4001) {
                    if (error.data) {
                        setErrorMessage(error.data.message);
                    }
                    else {
                        setErrorMessage(error.message);
                    }
                }
                else {
                    setErrorMessage('User rejected transaction.');
                }
            })
    }

    const handleSearch = () => {
        toggleSearchModal();
    }

    const handleWithdraw = () => {
        if (!account) {
            toggleWalletModal();
            return;
        }

        withdraw();
    }

    async function withdraw() {
        if (!chainId || !provider || !account) return
        const contract = getPremiumContract(chainId, provider, account)

        let estimate,
            method: (...args: any) => Promise<TransactionResponse>,
            args: Array<string | string[] | number>,
            value: BigNumber | null;

        estimate = isEth ? contract.estimateGas.withdrawETHFunds : contract.estimateGas.withdrawUSDCFunds
        method = isEth ? contract.withdrawETHFunds : contract.withdrawUSDCFunds
        args = [
            isEth ? userEthDeposit.toString() : userUsdcDeposit.toString()
        ]

        console.log(args);

        setAttemptingTxn(true)
        await estimate(...args, {})
            .then(estimatedGasLimit =>
                method(...args, {
                    ...(value ? { value } : {}),
                    gasLimit: calculateGasMargin(estimatedGasLimit)
                }).then(response => {
                    setAttemptingTxn(false)

                    addTransaction(response, {
                        summary:
                            'Withdraw has been successed.'
                    })
                    setTxHash(response.hash)
                    setErrorMessage('');
                })
            )
            .catch(error => {
                setAttemptingTxn(false)
                // we only care if the error is something _other_ than the user rejected the tx
                if (error?.code !== 4001) {
                    if (error.data) {
                        setErrorMessage(error.data.message);
                    }
                    else {
                        setErrorMessage(error.message);
                    }
                }
                else {
                    setErrorMessage('User rejected transaction.');
                }
            })
    }

    return (
        (chainId !== ChainId.MAINNET
            && chainId !== ChainId.GÖRLI)
            ? <NotAvailableChain />
            :
            <>

                <NftSearchModal />

                <Wrapper>

                    <PremiumMyFundsTabs />

                    <InnerWrapper>

                        <TitleLine>
                            <Subtitle>Owned NFTs</Subtitle>
                            <ButtonSearch onClick={handleSearch}>
                                <img src={nftIcon} alt='NFT' />
                                <Text>Search</Text>
                            </ButtonSearch>
                        </TitleLine>

                        <NftItems>
                            {
                                (userNftIds && userNftIds.length > 0) ?
                                    <AliceCarousel
                                        mouseTracking
                                        disableDotsControls={true}
                                        disableButtonsControls={true}
                                        autoWidth={true}
                                        infinite={false}
                                    >
                                        {
                                            userNftIds.map((nftId, idx) => <NftItem key={idx}>

                                                <NftDrop />
                                                <NftImg src={getTokenImage(nftId)} />
                                                <NftText>{getTokenName(nftId)}</NftText>
                                                <NftYield>
                                                    {getTokenAmount(isEth, userNftYields[nftId])} {coinName}
                                                </NftYield>
                                                <NftClaimButton onClick={() => handleClaimNFT(nftId)} disabled={isPending || attemptingTxn}>Claim</NftClaimButton>

                                            </NftItem>)
                                        }
                                    </AliceCarousel>
                                    : <NftEmpty>
                                        No Items
                                    </NftEmpty>
                            }
                        </NftItems>

                        <FundsDivider />

                        <FlexLine>

                            <TextLine>

                                <FundLine>
                                    Current APY: {getAPYText(isEth ? ethAPY : usdcAPY)}%
                                </FundLine>
                                <FundLine>
                                    My Principal: {userDeposit ?? '0'} {coinName}
                                </FundLine>
                                <FundLine>
                                    Yield generated: {userYield ?? '0'} {coinName}
                                </FundLine>

                                {
                                    errorMessage ? <TYPE.error error={true} marginLeft={'12px;'}>
                                        {errorMessage}
                                    </TYPE.error> : null
                                }

                                <Flex style={{ gap: "24px" }}>
                                    <ButtonPrimary onClick={handleClaim} disabled={isPending || attemptingTxn}>Claim</ButtonPrimary>
                                    <ButtonPrimary onClick={handleWithdraw} disabled={isPending || attemptingTxn}>Withdraw</ButtonPrimary>
                                </Flex>

                            </TextLine>

                            <ImgLine>
                                <img src={clip5Img} alt="HOKKFi" />
                            </ImgLine>

                        </FlexLine>

                    </InnerWrapper>

                </Wrapper>

            </>
    )
}
