import React from 'react'
import { SingleStakingInfo, useGetETHReward } from 'state/stake/hooks'
import { unwrappedToken } from 'utils/wrappedCurrency'

import btc from '../../assets/images/btc.png'
import btcMn from '../../assets/images/btcMn.png'
import { Link, NavLink } from 'react-router-dom'
import useGovernanceToken from 'hooks/useGovernanceToken'
import { currencyId } from 'utils/currencyId'
import { Break } from 'components/earn/styled'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { getNativeSymbol } from 'utils'
import { useWeb3React } from '@web3-react/core'

export default function StackItem({
  stakingInfo,
  isArchived
}: {
  stakingInfo: SingleStakingInfo
  isArchived: boolean
}) {
  const { chainId } = useWeb3React()
  const token = stakingInfo.token
  const currency = unwrappedToken(token)
  const ethReward = useGetETHReward(stakingInfo ?? undefined)
  const govToken = useGovernanceToken()

  const isStaking = Boolean(stakingInfo.stakedAmount.greaterThan('0'))

  // const govTokenPrice = undefined

  return (
    <div className="cardIn">
      <div className="cardHead d-flex">
        <div className="iconWrap d-flex">
          <ul className="iconImg">
            <DoubleCurrencyLogo currency0={currency} currency1={undefined} size={24} />
          </ul>
          <h4>{currency.symbol}</h4>
        </div>
        <div className="mg-l-auto">
          <NavLink to={`/staking/${currencyId(currency)}`} className="btnMain">
            {isStaking || isArchived ? 'Manage' : 'Deposit'}
          </NavLink>
        </div>
      </div>
      <div className="cardContent">
        <ul className="cardList d-flex">
          <li>
            APR*
            <span className="mg-l-auto">
              {stakingInfo.apr && stakingInfo.apr.greaterThan('0')
                ? `${stakingInfo.apr.multiply('100').toSignificant(4, { groupSeparator: ',' })}%`
                : 'To be determined'}
            </span>
          </li>
          <li>
            Your Staked {currency.symbol}
            <span className="mg-l-auto">
              {stakingInfo?.stakedAmount?.toSignificant(6) ?? '-'} {currency.symbol}
            </span>
          </li>

          <li>
            Emission rate
            <span className="mg-l-auto">
              {stakingInfo
                ? stakingInfo.active
                  ? `${stakingInfo.poolRewardsPerBlock.toSignificant(3, { groupSeparator: ',' })} 
                                        ${govToken?.symbol} / Block`
                  : `0 ${govToken?.symbol} / Block`
                : '-'}
            </span>
          </li>
          {stakingInfo.pid === 0 && (
            <li>
              {getNativeSymbol(chainId)} Reward
              <span className="mg-l-auto">
                {ethReward?.toSignificant(6)} {getNativeSymbol(chainId)}
              </span>
            </li>
          )}
          {isStaking && (
            <>
              <Break />
              <li>
                Your Unlocked Rewards <span className="mg-l-auto">🔓</span>
                {stakingInfo
                  ? stakingInfo.active
                    ? `${stakingInfo.unlockedEarnedAmount.toSignificant(4, { groupSeparator: ',' })} ${
                        govToken?.symbol
                      }`
                    : `0 ${govToken?.symbol}`
                  : '-'}
              </li>
              <li>
                Your Locked Rewards <span className="mg-l-auto">🔒</span>
                {stakingInfo
                  ? stakingInfo.active
                    ? `${stakingInfo.lockedEarnedAmount.toSignificant(4, { groupSeparator: ',' })} ${govToken?.symbol}`
                    : `0 ${govToken?.symbol}`
                  : '-'}
              </li>
              <Break />
              <li>
                Your Total Rewards <span className="mg-l-auto">⚡</span>
                {stakingInfo
                  ? stakingInfo.active
                    ? `${stakingInfo.earnedAmount.toSignificant(4, { groupSeparator: ',' })} ${govToken?.symbol}`
                    : `0 ${govToken?.symbol}`
                  : '-'}
              </li>
            </>
          )}
          {/* {isStaking && (
                        <>
                            <Break />
                            <StatContainerTop>
                                <RowBetween>
                                    <TYPE.white> Your Unlocked Rewards </TYPE.white>
                                    <TYPE.white>
                                        <span role="img" aria-label="wizard-icon" style={{ marginRight: '0.5rem' }}>
                                            🔓
                                        </span>
                                        {stakingInfo
                                            ? stakingInfo.active
                                                ? `${stakingInfo.unlockedEarnedAmount.toSignificant(4, { groupSeparator: ',' })} ${govToken?.symbol
                                                }`
                                                : `0 ${govToken?.symbol}`
                                            : '-'}
                                    </TYPE.white>
                                </RowBetween>
                                <RowBetween>
                                    <TYPE.white> Your Locked Rewards </TYPE.white>
                                    <TYPE.white>
                                        <span role="img" aria-label="wizard-icon" style={{ marginRight: '0.5rem' }}>
                                            🔒
                                        </span>
                                        {stakingInfo
                                            ? stakingInfo.active
                                                ? `${stakingInfo.lockedEarnedAmount.toSignificant(4, { groupSeparator: ',' })} ${govToken?.symbol
                                                }`
                                                : `0 ${govToken?.symbol}`
                                            : '-'}
                                    </TYPE.white>
                                </RowBetween>
                            </StatContainerTop>
                            <Break />
                            <BottomSection showBackground={true}>
                                <TYPE.black color={'white'} fontWeight={500}>
                                    <span>Your Total Rewards</span>
                                </TYPE.black>

                                <TYPE.black style={{ textAlign: 'right' }} color={'white'} fontWeight={500}>
                                    <span role="img" aria-label="wizard-icon" style={{ marginRight: '0.5rem' }}>
                                        ⚡
                                    </span>
                                    {stakingInfo
                                        ? stakingInfo.active
                                            ? `${stakingInfo.earnedAmount.toSignificant(4, { groupSeparator: ',' })} ${govToken?.symbol}`
                                            : `0 ${govToken?.symbol}`
                                        : '-'}
                                </TYPE.black>
                            </BottomSection>
                        </>
                    )} */}
        </ul>
      </div>
    </div>
  )
}
