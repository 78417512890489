import React from 'react'
import styled from 'styled-components'
import { useUserAsset } from 'state/user/hooks';
import { ButtonPrimary } from 'components/Button';
import { Text } from 'rebass'

import ethIcon from '../../assets/images/eth-coin.png'
import usdcIcon from '../../assets/images/usdc-coin.png'



const CoinsLine = styled.div`
    display: flex;
    gap: 32px;
    justify-content: flex-start;

    ${({ theme }) => theme.mediaWidth.upToSmall`
      gap: 12px;
      font-size:10px;
    `};
`

const ButtonCoin = styled(ButtonPrimary)`
    width: 160px;
    height: 48px;
    justify-content: flex-start;
    padding: 8px 16px;
    gap: 12px;
    opacity: 0.5;

    &.active {
      opacity: 1;
    }

    & img {
      width: 32px;
      ${({ theme }) => theme.mediaWidth.upToMedium`
      width: 20px;
    `};
    }

    ${({ theme }) => theme.mediaWidth.upToMedium`
      width: 120px;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
      width: inherit;
      height: 42px;
      padding: 0 10px;
      gap: 8px;
    `};
    
  
`


export function CoinSelector() {

  const [isEth, setIsEth] = useUserAsset()

  const handleETH = () => {
    setIsEth(true);
  }

  const handleUSDC = () => {
    setIsEth(false);
  }

  return (
    <CoinsLine>
      <ButtonCoin onClick={handleETH} className={isEth ? 'active' : ''}>
        <img src={ethIcon} alt='ETH' />
        <Text>ETH</Text>
      </ButtonCoin>
      <ButtonCoin onClick={handleUSDC} className={isEth ? '' : 'active'}>
        <img src={usdcIcon} alt='USDC' />
        <Text>USDC</Text>
      </ButtonCoin>
    </CoinsLine>
  )
}
