import { Checkbox, CheckboxProps } from '@mui/material';
import { ButtonPrimary } from 'components/Button';
import { TransactionType } from 'constants/index';
import { useWeb3React } from '@web3-react/core';
import React, { useEffect, useState } from 'react'
import { DownloadCloud } from 'react-feather';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Flex, Text } from 'rebass';
import axios from 'axios';
import styled from 'styled-components';
import useTheme from '../../hooks/useTheme'
import { ButtonEmpty } from '../../components/Button'
import { ArrowBack } from '@material-ui/icons';
import { getBridgeObject } from 'utils';
import moment from 'moment';
import { BodyWrapper } from 'pages/AppBody'
import { tryParseAmount } from 'state/swap/hooks';


const Wrapper = styled(BodyWrapper)`
    padding: 2rem;
    min-height: calc(100vh - 120px);
    ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 2rem 1rem;
  `};
`


const TitleWrapper = styled(Text)`
  font-size: 24px;
  text-align: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 18px;
  `};

`

const TransactionsWrapper = styled.div`
  margin-top: 32px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
  `};
`

const MobileTransWrapper = styled.div`

  display: none;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};

`


const TransactionRow = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  grid-template-columns: 50px 1fr 120px 120px 1fr 1fr 100px 1fr;
  background: rgba(21, 21, 33, 0.74);
  backdrop-filter: blur(7px);
  border-radius: 10px;
  padding: 16px 8px;
  postion:relative
  margin: 16px 0px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  padding: 16px 5px;
  font-size: 12px;
`};
${({ theme }) => theme.mediaWidth.upToSmall`
  font-size: 12px;
  width: 100%;
`};
${({ theme }) => theme.mediaWidth.upToExtraSmall`
  font-size: 8px;
  width: 100%;
`};
`

const TransactionHeader = styled(TransactionRow)`
  background: rgba(21, 21, 33, 0.74);
  color: #92929D;
  padding: 8px 10px;

`

const MobileTransHeader = styled(TransactionHeader)`
  grid-template-columns: 40px 2fr 1fr 1fr 1fr;

`

const MobileTransRow = styled.div`
  font-size: 14px;
  background: rgba(21, 21, 33, 0.74);
  backdrop-filter: blur(7px);
  border-radius: 10px;
  padding: 8px;
  margin: 16px 0px;

  .primary {
    display: grid;
    align-items: center;
    grid-template-columns: 40px 2fr 1fr 1fr 1fr;
  }

  .secondary {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 8px;
  }

`

const ButtonDetailed = styled(ButtonPrimary)`
  width: 50px;
  padding: 10px;
  border-radius: 10px;
  margin-left: 12px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 40px;
    padding: 5px;
    border-radius: 5px;
  `};

`

const ButtonBack = styled(ButtonEmpty)`
  padding: 0px;
  margin: 0px;
  color: white;

`

function StyledCheckbox(props: CheckboxProps) {
    return (
        <Checkbox
            sx={{
                'color': '#92929D',
                'padding':'0px',
            }}
            disableRipple
            color="default"
            {...props}
        />
    )
}

export default function BridgeHistory({ history }: RouteComponentProps) {

    const theme = useTheme()

    const { account } = useWeb3React()

    const [transactions, setTransactions] = useState<Array<TransactionType>>([]);

    useEffect(() => {
        const getTransactions = async () => {
            if (!account) {
                return;
            }

            const bridge = await getBridgeObject();
            if (!bridge) {
                return;
            }

            const { data } = await axios.get<any>(bridge['url'] + '/history/' + account)
            if (data.transactions) {
                setTransactions(data.transactions);
            }

        }
        getTransactions();
    }, [
        account
    ]);

    const shortenTxId = (txId: string) => {
        return `${txId.substring(0, 5)}...${txId.substr(-3, 3)}`
    }


    return (
        <>

            <Wrapper>

                <TitleWrapper display="flex">

                    <ButtonBack
                        width="120px"
                        as={Link}
                        to={'/bridge'}
                    >
                        <ArrowBack />
                    </ButtonBack>

                    <Text flex="1">
                        History
                    </Text>

                </TitleWrapper>

                <TransactionsWrapper>

                    <TransactionHeader>
                        <StyledCheckbox />
                        <Text>Order ID </Text>
                        <Text>From</Text>
                        <Text>To</Text>
                        <Text>Amount</Text>
                        <Text>Date / Time</Text>
                        <Text>Status</Text>
                        <Text>Transaction</Text>
                    </TransactionHeader>

                    {transactions.map((transaction) => (
                        <TransactionRow key={transaction.orderID}>
                            <StyledCheckbox />
                            <Text>#{transaction.orderID} </Text>
                            <Text>{transaction.startChain}</Text>
                            <Text>{transaction.endChain}</Text>
                            <Text>{tryParseAmount(transaction.amount.toString())?.toExact()} HOKK</Text>
                            <Text>{moment(transaction.detectionTime).format('LLL')}</Text>
                            <Text color={transaction.confirmed ? theme.primary1 : theme.red1}>{transaction.confirmed ? 'Confirmed' : 'Pending'}</Text>
                            <Flex alignItems="center">
                                <Text width="120px">{shortenTxId(transaction.hash)}</Text>
                                <ButtonDetailed
                                    as={Link}
                                    to={{
                                        pathname: '/detail/' + transaction.hash,
                                        state: transaction
                                    }}
                                >
                                    <DownloadCloud />
                                </ButtonDetailed>
                            </Flex>
                        </TransactionRow>
                    ))}

                </TransactionsWrapper>

                <MobileTransWrapper>

                    <MobileTransHeader>
                        <StyledCheckbox/>
                        <Text>Order ID </Text>
                        <Text>From</Text>
                        <Text>To</Text>
                        <Text>Amount</Text>
                    </MobileTransHeader>

                    {transactions.map((transaction) => (
                        <MobileTransRow key={transaction.orderID}>
                            <div className="primary">
                                <StyledCheckbox />
                                <Text>#{transaction.orderID} </Text>
                                <Text>{transaction.startChain}</Text>
                                <Text>{transaction.endChain}</Text>
                                <Text>{tryParseAmount(transaction.amount.toString())?.toExact()}</Text>
                            </div>
                            <div className="secondary">
                                <Text color={transaction.confirmed ? theme.primary1 : theme.red1} marginLeft={16}>{transaction.confirmed ? 'Confirmed' : 'Pending'}</Text>
                                <Text>{shortenTxId(transaction.hash)}</Text>
                                <ButtonDetailed
                                    as={Link}
                                    to={'/detail/' + transaction.hash}
                                >
                                    <DownloadCloud />
                                </ButtonDetailed>
                            </div>
                        </MobileTransRow>
                    ))}

                </MobileTransWrapper>

            </Wrapper>

        </>

    )
}
