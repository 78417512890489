import styled from 'styled-components'
import React, { useCallback, useMemo, useState } from 'react'
import { Flex, Text } from 'rebass'
import { parseUnits } from 'ethers/lib/utils'
import { TransactionResponse } from '@ethersproject/abstract-provider'
import { BigNumber } from 'ethers'
import { ChainId, Token, TokenAmount } from 'sdk'

import { PieChart, Pie, Cell } from 'recharts'

import { useWeb3React } from '@web3-react/core'
import { useWalletModalToggle } from 'state/application/hooks'
import { useAaveAPY, /*useCurrentAPY*/ useUserData } from 'hooks/useContract'
import { useWindowSize } from 'hooks/useWindowSize'
import { useETHBalances, useTokenBalance } from 'state/wallet/hooks'
import { useIsTransactionPending, useTransactionAdder } from 'state/transactions/hooks'
import { useUserAsset } from 'state/user/hooks'

import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { calculateGasMargin, getAPYText, getPremiumContract, tryParseEth, tryParseUsdc } from 'utils'
import { InnerWrapper, TYPE } from 'theme'
import { ButtonPrimary } from 'components/Button'

import { ChevronDown, ChevronUp } from 'react-feather'
import { depositPeriods, USDC, USDC_DECIMALS } from 'constants/index'
import { HOKK_PREMIUM_ADDRESS } from 'constants/abis/hokkpremium'
import { BodyWrapper } from 'pages/AppBody'

import clip4Img from '../../assets/images/clip4.png'
import { DropdownButton } from 'components/Bridge/styled'
import AmountSelector from 'components/Bridge/AmountSelector'
import { PremiumDepositTabs } from 'components/NavigationTabs'

import { NotAvailableChain } from '../../theme'

const Wrapper = styled(BodyWrapper)`
  padding: 2rem;
  min-height: calc(100vh - 120px);

  ${({ theme }) => theme.mediaWidth.upToMedium`
        padding: 2rem 10px;
    `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
        padding: 1rem;
        display: block;
        width: 100%;
        max-width: 100%;
    `};
`

const TextLine = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    text-align: center;
  `};
`

const MobileImg = styled.img`
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  display: none;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: block;
  `};
`

const DepositLine = styled.div`
  background: #181e27;
  border-radius: 60px;
  padding: 12px 24px;
  margin: 24px 0px;
  flex: 1;
  font-size: 18px;
  align-items: center;
`

const LockupLine = styled(DepositLine)`
  display: flex;
  justify-content: space-between;
  position: relative;
`

const PeriodWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 64px;
  left: 0px;
  z-index: 1000;
  padding: 12px 8px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.17);
  background: rgba(8, 5, 18, 0.8);
  backdrop-filter: blur(2px);
`

const PeriodItem = styled.div`
  font-size: 18px;
  font-weight: light;
  line-height: 42px;
  cursor: pointer;
  padding: 0px 24px;

  &:hover {
    background: #0c0a1ba0;
    border-radius: 16px;
  }
`

const PieWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: block;
  `};
`

const PieLabels = styled.div`
  flex: 1;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
    flex-direction: column;
    align-items: center;
  `};
`

const PieLabel = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 0px;
`

const PieCircle = styled.div<{ color: string }>`
  width: 12px;
  height: 12px;
  background: ${({ color }) => color};
  border-radius: 50%;
  margin-right: 12px;
`

const PieLabelText = styled.div`
  display: flex;
`

const InputLine = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin: 32px 0px;
`

export default function Deposit() {
  const { chainId, account, provider } = useWeb3React()
  const toggleWalletModal = useWalletModalToggle()
  const addTransaction = useTransactionAdder()
  const { userEthDeposit, userUsdcDeposit, userEthYield, userUsdcYield } = useUserData(account)
  const [isEth] = useUserAsset()
  const coinName = isEth ? 'ETH' : 'USDC'
  // const APY = useCurrentAPY(isEth);
  const { ethAPY, usdcAPY } = useAaveAPY()

  const usdcToken: Token | null | undefined = USDC[ChainId.MAINNET]
  const usdcApproveAmount = usdcToken
    ? new TokenAmount(usdcToken, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
    : undefined
  const [approval, approveCallback] = useApproveCallback(usdcApproveAmount, HOKK_PREMIUM_ADDRESS)

  const usdcBalance = useTokenBalance(account ?? undefined, usdcToken ? usdcToken : undefined)
  const ethBalance = useETHBalances(account ? [account] : [])?.[account ?? '']

  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [amount, setAmount] = useState<string>('')
  const [periodExpanded, setPeriodExpanded] = useState<boolean>(false)
  const [periodIdx, setPeriodIdx] = useState<number>(0)
  const windowSize = useWindowSize()

  const userDeposit = isEth ? tryParseEth(userEthDeposit) : tryParseUsdc(userUsdcDeposit)
  const userYield = isEth ? tryParseEth(userEthYield) : tryParseUsdc(userUsdcYield)

  const data = useMemo(() => {
    return [
      {
        angle: parseFloat(userDeposit ?? '0'),
        label: 'Eth',
        color: '#1E75FF',
        radius: 125,
        radius0: 155
      },
      {
        angle: parseFloat(userYield ?? '0'),
        label: 'Funds',
        color: '#FF974A',
        radius: 125,
        radius0: 155
      }
    ]
  }, [userDeposit, userYield])

  const isPending = useIsTransactionPending(txHash)

  const handleExpand = () => {
    setPeriodExpanded(!periodExpanded)
  }

  const selectPeriod = (idx: number) => {
    setPeriodIdx(idx)
    setPeriodExpanded(false)
  }

  const pieSize = useMemo(() => {
    if (windowSize.width !== undefined && windowSize.width > 768) {
      return 300
    } else {
      return 200
    }
  }, [windowSize])

  const handleUserAmount = useCallback(_amount => {
    setAmount(_amount)
  }, [])

  async function depositEth() {
    if (!chainId || !provider || !account) return
    const contract = getPremiumContract(chainId, provider, account)

    let estimate,
      method: (...args: any) => Promise<TransactionResponse>,
      args: Array<string | string[] | number>,
      value: BigNumber | null

    const lockupTimestamp = 60 * 60 * 24 * 30 * depositPeriods[periodIdx].value
    const lockupPeriod: number = Math.round(new Date().getTime() / 1000) + lockupTimestamp
    console.log(lockupPeriod)

    estimate = contract.estimateGas.depositETH
    method = contract.depositETH
    args = [lockupPeriod]
    value = parseUnits(amount, 'ether')

    setAttemptingTxn(true)
    await estimate(...args, value ? { value } : {})
      .then(estimatedGasLimit =>
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit)
        }).then(response => {
          setAttemptingTxn(false)

          addTransaction(response, {
            summary: 'Deposit Successed.'
          })
          setTxHash(response.hash)
          setErrorMessage('')
        })
      )
      .catch(error => {
        setAttemptingTxn(false)

        console.log(error)

        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          if (error.data) {
            setErrorMessage(error.data.message)
          } else if (error.reason) {
            setErrorMessage(error.reason)
          } else {
            setErrorMessage(error.message)
          }
        } else {
          setErrorMessage('User rejected transaction.')
        }
      })
  }

  async function depositUsdc() {
    if (!chainId || !provider || !account) return
    const contract = getPremiumContract(chainId, provider, account)

    let estimate,
      method: (...args: any) => Promise<TransactionResponse>,
      args: Array<string | string[] | number>,
      value: BigNumber | null

    const _amount = parseUnits(amount, USDC_DECIMALS).toString()
    const lockupPeriod: number =
      Math.round(new Date().getTime() / 1000) + 60 * 60 * 24 * 30 * depositPeriods[periodIdx].value

    estimate = contract.estimateGas.depositUSDC
    method = contract.depositUSDC
    args = [_amount, lockupPeriod]
    value = null

    setAttemptingTxn(true)
    await estimate(...args, value ? { value } : {})
      .then(estimatedGasLimit =>
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit)
        }).then(response => {
          setAttemptingTxn(false)

          addTransaction(response, {
            summary: 'Deposit Successed.'
          })
          setTxHash(response.hash)
          setErrorMessage('')
        })
      )
      .catch(error => {
        setAttemptingTxn(false)

        console.log(error)

        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          if (error.data) {
            setErrorMessage(error.data.message)
          } else if (error.reason) {
            setErrorMessage(error.reason)
          } else {
            setErrorMessage(error.message)
          }
        } else {
          setErrorMessage('User rejected transaction.')
        }
      })
  }

  const chartData = [
    { name: 'Asset', value: 100 },
    { name: 'Funds', value: 100 }
  ]
  const chartColor = ['#1E75FF', '#FF974A']

  return chainId !== ChainId.MAINNET && chainId !== ChainId.GÖRLI ? (
    <NotAvailableChain />
  ) : (
    <Wrapper>
      <PremiumDepositTabs />

      <InnerWrapper>
        <TextLine>
          <MobileImg src={clip4Img} alt="HOKKFi" />

          <PieWrapper>
            <PieChart width={400} height={400}>
              <Pie data={chartData} dataKey="value" cx="50%" cy="50%" innerRadius={90} outerRadius={100} label>
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={chartColor[index % chartColor.length]} />
                ))}
              </Pie>
            </PieChart>
            <PieLabels>
              <PieLabel>
                <PieCircle color={chartColor[0]} />
                <PieLabelText>
                  {coinName}: {userDeposit ?? '0'}
                </PieLabelText>
              </PieLabel>
              <PieLabel>
                <PieCircle color={chartColor[1]} />
                <PieLabelText>Funds: {userYield ?? '0'}</PieLabelText>
              </PieLabel>
            </PieLabels>
          </PieWrapper>
        </TextLine>

        <LockupLine>
          <Text>Lockup For: {depositPeriods[periodIdx].name}</Text>
          <DropdownButton onClick={handleExpand}>
            {periodExpanded ? <ChevronDown color="black" size={24} /> : <ChevronUp color="black" size={24} />}
          </DropdownButton>
          {periodExpanded ? (
            <PeriodWrapper>
              {depositPeriods.map((period, idx) => (
                <PeriodItem key={idx} onClick={() => selectPeriod(idx)}>
                  {period.name}
                </PeriodItem>
              ))}
            </PeriodWrapper>
          ) : null}
        </LockupLine>

        <DepositLine>Expected APY: {getAPYText(isEth ? ethAPY : usdcAPY)}%</DepositLine>

        <AmountSelector amount={amount} balance={isEth ? ethBalance : usdcBalance} onChange={handleUserAmount} />

        {account ? (
          <InputLine>
            {isEth ? (
              <ButtonPrimary onClick={depositEth} disabled={isPending || attemptingTxn}>
                I want to deposit
              </ButtonPrimary>
            ) : approval === ApprovalState.APPROVED ? (
              <ButtonPrimary onClick={depositUsdc} disabled={isPending || attemptingTxn}>
                I want to deposit
              </ButtonPrimary>
            ) : (
              <ButtonPrimary
                onClick={approveCallback}
                disabled={approval === ApprovalState.PENDING || isPending || attemptingTxn}
              >
                Approve
              </ButtonPrimary>
            )}
          </InputLine>
        ) : (
          <InputLine>
            <ButtonPrimary onClick={toggleWalletModal} disabled={isPending || attemptingTxn}>
              Connect Wallet
            </ButtonPrimary>
          </InputLine>
        )}

        {errorMessage ? (
          <Flex justifyContent={'center'} marginY={'24px'}>
            <TYPE.error error={true}>{errorMessage}</TYPE.error>
          </Flex>
        ) : null}
      </InnerWrapper>
    </Wrapper>
  )
}
