import { ChainId } from 'sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId: number]: string } = {
  [ChainId.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  [ChainId.GÖRLI]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.SEPOLIA]: '0x25Eef291876194AeFAd0D60Dff89e268b90754Bb',

  [ChainId.BSC_MAINNET] : '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb',
  [ChainId.BSC_TESTNET] : '0x6e5BB1a5Ad6F68A8D7D6A5e47750eC15773d6042',
  
  [ChainId.HECO_MAINNET] : '0x2C55D51804CF5b436BA5AF37bD7b8E5DB70EBf29',
  [ChainId.HECO_TESTNET] : '0x01A4bFec8Cfd2580640fc6Bd0CB11461a6C804f1'
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
