import React, { useContext } from 'react'
import { useWeb3React } from '@web3-react/core'

import { AutoColumn, ColumnCenter } from '../Column'
import styled, { ThemeContext } from 'styled-components'
import { RowBetween } from '../Row'
import { TYPE, CloseIcon, CustomLightSpinner } from '../../theme'
import { ArrowUpCircle } from 'react-feather'

import Circle from '../../assets/images/blue-loader.svg'
import { getEtherscanLink, getEtherscanName } from '../../utils'
import { ExternalLink } from '../../theme/components'

const ConfirmOrLoadingWrapper = styled.div`
  width: 100%;
  padding: 24px;
`

const ConfirmedIcon = styled(ColumnCenter)`
  padding: 60px 0;
`

export function LoadingView({ children, onDismiss }: { children: any; onDismiss: () => void }) {
  return (
    <ConfirmOrLoadingWrapper>
      <RowBetween>
        <div />
        <CloseIcon onClick={onDismiss} />
      </RowBetween>
      <ConfirmedIcon>
        <svg viewBox="0 0 120 120" version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink" className='loaderIcon'>

          <symbol id="s--circle">
            <circle r="10" cx="20" cy="20"></circle>
          </symbol>

          <g className="g-circles g-circles--v1">
            <g className="g--circle">
              <use xlinkHref="#s--circle" className="u--circle" />
            </g>
            <g className="g--circle">
              <use xlinkHref="#s--circle" className="u--circle" />
            </g>
            <g className="g--circle">
              <use xlinkHref="#s--circle" className="u--circle" />
            </g>
            <g className="g--circle">
              <use xlinkHref="#s--circle" className="u--circle" />
            </g>
            <g className="g--circle">
              <use xlinkHref="#s--circle" className="u--circle" />
            </g>
            <g className="g--circle">
              <use xlinkHref="#s--circle" className="u--circle" />
            </g>
            <g className="g--circle">
              <use xlinkHref="#s--circle" className="u--circle" />
            </g>
            <g className="g--circle">
              <use xlinkHref="#s--circle" className="u--circle" />
            </g>
            <g className="g--circle">
              <use xlinkHref="#s--circle" className="u--circle" />
            </g>
            <g className="g--circle">
              <use xlinkHref="#s--circle" className="u--circle" />
            </g>
            <g className="g--circle">
              <use xlinkHref="#s--circle" className="u--circle" />
            </g>
            <g className="g--circle">
              <use xlinkHref="#s--circle" className="u--circle" />
            </g>
          </g>
        </svg>
      </ConfirmedIcon>
      <AutoColumn gap="100px" justify={'center'}>
        {children}
        <TYPE.subHeader>Confirm this transaction in your wallet</TYPE.subHeader>
      </AutoColumn>
    </ConfirmOrLoadingWrapper>
  )
}

export function SubmittedView({
  children,
  onDismiss,
  hash
}: {
  children: any
  onDismiss: () => void
  hash: string | undefined
}) {
  const theme = useContext(ThemeContext)
  const { chainId } = useWeb3React()

  return (
    <ConfirmOrLoadingWrapper>
      <RowBetween>
        <div />
        <CloseIcon onClick={onDismiss} />
      </RowBetween>
      <ConfirmedIcon>
        <ArrowUpCircle strokeWidth={0.5} size={90} color={theme.primary1} />
      </ConfirmedIcon>
      <AutoColumn gap="100px" justify={'center'}>
        {children}
        {chainId && hash && (
          <ExternalLink href={getEtherscanLink(chainId, hash, 'transaction')} style={{ marginLeft: '4px' }}>
            <TYPE.subHeader>View transaction on {getEtherscanName(chainId)}</TYPE.subHeader>
          </ExternalLink>
        )}
      </AutoColumn>
    </ConfirmOrLoadingWrapper>
  )
}
