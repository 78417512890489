import { Link, RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import React, { useState } from 'react'
import { BigText, MediumText, SmallText } from 'theme'
import { ChevronDown, ChevronRight } from 'react-feather'
import { ButtonEmpty, ButtonPrimary } from 'components/Button'
import clip1Img from '../../assets/images/clip1.png'
import clip2Img from '../../assets/images/clip2.png'
import clip3Img from '../../assets/images/clip3.png'
import clip6Img from '../../assets/images/clip6.png'
import clip8Img from '../../assets/images/clip8.png'
import featuresBg from '../../assets/images/features-bg.png'
import aaveIcon from '../../assets/images/aave.png'
import compoundIcon from '../../assets/images/compound.png'
import yearnIcon from '../../assets/images/yearn.png'
import ethLogo from '../../assets/images/eth-coin.png'
import usdcLogo from '../../assets/images/usdc-coin.png'
import { /*useCurrentAPY,*/ useAaveAPY, useETHPrice, useLockedAmount, useTotalETHDeposit, useTotalUSDCDeposit, useYieldAmount } from 'hooks/useContract'
import { Flex, Text } from 'rebass'
import { getAPYText } from 'utils'
import { useWeb3React } from '@web3-react/core'
import { NotAvailableChain } from '../../theme'
import { ChainId } from 'sdk'

const Wrapper = styled.div`
  background-color: rgba(18, 17, 40, 0.65);
  background-image: ${() => `url(${featuresBg})`};
  background-size: cover;
  background-position: center;
  border-radius: 24px;
  padding: 64px;
  
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 40px 10px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    background: none;
    padding: 0px;
  `};
`

const FlexLine = styled.div`
  display: flex;
  gap: 32px;
  
  ${({ theme }) => theme.mediaWidth.upToLarge`
    display: block;
  `};
`

const TextLine = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  
  ${({ theme }) => theme.mediaWidth.upToMedium`
    text-align: center;
  `};
`

const ImgLine = styled.div`
  flex: 1;
  margin-left: 64px;


  
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin: 0px;
    text-align: center;
    
    & img {
      width: 300px;
    }
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    & img {
      width: 300px;
      max-width: 100%;
    }
  `};
`

const CenterLine = styled.div`
  flex: 1;
  text-align: center;
`

const HomeText = styled(BigText)`
  font-size: 36px;
  line-height: 48px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  font-size: 25px;
  line-height: 40px;
`};
${({ theme }) => theme.mediaWidth.upToExtraSmall`
  font-size: 18px;
  line-height: 40px;
`};
`

const EthLogo = styled.img`
  height: 32px;
  width: 32px;
  object-fit: contain;
`

const TrustLine = styled.div`
  text-align: center;
  margin: 42px 0px;
`

const TrustItems = styled.div`
  display: flex;
  margin: 24px 0px;
  background: linear-gradient(98deg,rgb(45 171 229 / 20%) -16%,rgb(149 40 255 / 20%) 100%);
  border-radius: 24px;
  padding: 16px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  padding: 16px 8px;
`};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 12px;
    max-width: 320px;
    margin: 24px auto;
  `};
`

const TrustItem = styled.div`
  flex: 1;

  & img {
    width: 100px;
    margin: 24px auto;
  }
`

const APYLine = styled.div`
  width: 150px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`

const APYText = styled(Text)`
  font-size: 20px;
  padding: 8px 0px;
`

const FeaturesLine = styled.div`
  background: rgba(8, 5, 18, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.17);
  box-sizing: border-box;
  backdrop-filter: blur(132px);
  border-radius: 24px;
  padding: 24px;
  margin: 24px 0px;
  
  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 320px;
    margin: 24px auto;
  `};
`

const FeaturesText = styled.div`
  text-align: center;
`

const FeatureIcon = styled.img`
  height: 120px;
  margin: 40px 0px;
`

const PremiumLine = styled(FlexLine)`
  margin: 64px 0px;
`

const ButtonView = styled(ButtonPrimary)`
    
    background: linear-gradient(95deg, rgb(45, 171, 229) -16%, rgb(149, 40, 255) 100%);
    box-shadow: rgb(135 82 247 / 40%) 10px 0px 45px;
    width: 180px;
    border-radius: 32px;
    padding: 12px 12px;
    font-weight: 500;
    text-align: center;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin: 24px auto;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width:100%;
`}; 
`

export default function Premium() {
  const { chainId } = useWeb3React()
  const [selectedIdx, setSelectedIdx] = useState<number>(-1);
  // const APY = useCurrentAPY(false);
  const { ethAPY, usdcAPY } = useAaveAPY();
  const totalEthDeposit = useTotalETHDeposit();
  const totalUsdcDeposit = useTotalUSDCDeposit();
  const ethPrice = useETHPrice();
  const lockedAmount = useLockedAmount(totalEthDeposit, totalUsdcDeposit, ethPrice);
  const yieldAmount = useYieldAmount(totalEthDeposit, totalUsdcDeposit, ethPrice);

  function prettyNumber(amount: number | undefined) {
    if (amount) {
      return amount?.toFixed(2);
    }
    else {
      return '0';
    }
  }

  return (
    (chainId !== ChainId.MAINNET
      && chainId !== ChainId.GÖRLI)
      ? <NotAvailableChain />
      :
      <Wrapper>
        <FlexLine>
          <TextLine>
            <HomeText>
              MAKE YOUR ETH <EthLogo src={ethLogo} alt="ETH" /> <br />
              AND USDC <EthLogo src={usdcLogo} alt="USDC" /> <br />
              WORK FOR YOU
            </HomeText>
            <SmallText fontSize={'22px'}>
              A DeFi aggregator enabling you to spread your ETH/USDC and HOKK NFTs to earn yield
            </SmallText>

            <Flex style={{ gap: '24px' }}>
              <ButtonView as={Link} to="/deposit">Deposit</ButtonView>
              <ButtonView as={Link} to="/my_funds">My Funds</ButtonView>
            </Flex>
          </TextLine>
          <ImgLine>
            <img src={clip1Img} alt="HOKKFi" />
          </ImgLine>
        </FlexLine>

        <FeaturesLine>

          <FeaturesText>
            <BigText>Features</BigText>
          </FeaturesText>
          <FlexLine>
            <CenterLine>
              <FeatureIcon src={clip6Img} />
              <MediumText>$ {prettyNumber(lockedAmount)}</MediumText>
              <MediumText>Locked</MediumText>
            </CenterLine>
            {
              /*
                <CenterLine>
                  <FeatureIcon src={clip7Img} />
                  <MediumText>{APY}%</MediumText>
                  <MediumText>APY</MediumText>
                </CenterLine>
              */
            }
            <CenterLine>
              <FeatureIcon src={clip8Img} />
              <MediumText>$ {prettyNumber(yieldAmount)}</MediumText>
              <MediumText>Yield generated</MediumText>
            </CenterLine>
          </FlexLine>

        </FeaturesLine>

        <TrustLine>
          <MediumText>Backed by trusted protocols</MediumText>
          <TrustItems>
            <TrustItem>
              <img src={aaveIcon} alt="Aave" />
              <APYLine>
                <APYText>ETH</APYText>
                <APYText>{getAPYText(ethAPY)}%</APYText>
              </APYLine>
              <APYLine>
                <APYText>USDC</APYText>
                <APYText>{getAPYText(usdcAPY)}%</APYText>
              </APYLine>
            </TrustItem>
            <TrustItem>
              <img src={compoundIcon} alt="Compound" />
              <APYLine>
                <APYText>ETH</APYText>
                <APYText>-</APYText>
              </APYLine>
              <APYLine>
                <APYText>USDC</APYText>
                <APYText>-</APYText>
              </APYLine>
            </TrustItem>
            <TrustItem>
              <img src={yearnIcon} alt="Yearn" />
              <APYLine>
                <APYText>ETH</APYText>
                <APYText>-</APYText>
              </APYLine>
              <APYLine>
                <APYText>USDC</APYText>
                <APYText>-</APYText>
              </APYLine>
            </TrustItem>
          </TrustItems>
        </TrustLine>

        <FlexLine>
          <TextLine>
            <MediumText fontWeight={'bold'}>Find the best APY for your ETH at the click of a button</MediumText>
            <SmallText color='#CCCCCC'>ETH is becoming more popular than ever thanks to its wide utility, however, when your ETH sits in your Web3 wallet, it’s not working for you long term. Using HOKK Premium, you can place capital (ETH) into a smart contract, choose preset lockup periods and be issued with estimations of their monthly yield. The smart contract will send the crypto to aggregator contracts like YFI, where it will be lent out for continuous yield. This yield will then be periodically claimed by the contract and converted into a stable token to preserve its value.</SmallText>
            <ButtonView as={Link} to="/deposit" >Explore</ButtonView>
          </TextLine>
          <ImgLine>
            <img src={clip2Img} alt="HOKKFi" />
          </ImgLine>
        </FlexLine>

        <PremiumLine>
          <ImgLine>
            <img src={clip3Img} alt="HOKKFi" />
          </ImgLine>
          <TextLine>
            <MediumText fontWeight={'bold'}>Get a complete overview and take control of your ETH</MediumText>
            <SmallText color='#CCCCCC'>
              HOKK Premium gives you the options to view your initial ETH contribution, the current APY, your percentage of the total pool, and current yield. Every ETH depostit you make shares a small fee that is given to the yield pool, instantly adding to the yield of earlier ETH providers. An additional 1% fee will be placed in the HOKK Finance ETH liquidity pool to maintain the token’s strong growth as the crypto pool expands. We aim to develop this decentralized product to its full extent by introducing a commemorative NFT mint. This mint will serve as a kickstart in liquidity, locking in ETH and providing each individual NFT with utility through HOKK Finance ecosystem interactions.
            </SmallText>
            <ButtonView as={Link} to="/deposit">Explore</ButtonView>
          </TextLine>
        </PremiumLine>

      </Wrapper>
  )
}
