import React from 'react'
import DoubleCurrencyLogo from '../DoubleLogo'
import { JSBI } from 'sdk'
import { ButtonPrimary } from '../Button'
import { StakingInfo } from '../../state/stake/hooks'
import { currencyId } from '../../utils/currencyId'
import { Break } from './styled'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import useGovernanceToken from '../../hooks/useGovernanceToken'
import { NavLink } from 'react-router-dom'
import { getNativeSymbol } from 'utils'
import { useWeb3React } from '@web3-react/core'

export default function PoolCard({ stakingInfo, isArchived }: { stakingInfo: StakingInfo; isArchived: boolean }) {
  const { chainId } = useWeb3React()
  const govToken = useGovernanceToken()
  const govTokenPrice = undefined

  const isStaking = Boolean(stakingInfo.stakedAmount.greaterThan('0'))
  // const poolSharePercentage = stakingInfo.poolShare.multiply(JSBI.BigInt(100))

  // get the color of the token
  const token0 = stakingInfo.tokens[0]
  const token1 = stakingInfo.tokens[1]
  const currency0 = unwrappedToken(token0)
  const currency1 = unwrappedToken(token1)
  const backgroundColor = '#000'
  return (
    <>
      <div className="cardIn">
        <div className="cardHead d-flex">
          <div className="iconWrap d-flex">
            <ul className="iconImg">
              <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={24} />
            </ul>
            <h4>
              {currency0.symbol}-{currency1.symbol}
            </h4>
          </div>
          <div className="mg-l-auto">
            <NavLink to={`/staking/${currencyId(currency0)}/${currencyId(currency1)}`} className="btnMain">
              {isStaking || isArchived ? 'Manage' : 'Deposit'}
            </NavLink>
          </div>
        </div>
        <div className="cardContent">
          <ul className="cardList d-flex">
            <li>
              APR*{' '}
              <span className="mg-l-auto">
                {stakingInfo.apr && stakingInfo.apr.greaterThan('0')
                  ? `${stakingInfo.apr.multiply('100').toSignificant(4, { groupSeparator: ',' })}%`
                  : 'To be determined'}
              </span>
            </li>
            <li>
              Total deposited{' '}
              <span className="mg-l-auto">
                {stakingInfo && stakingInfo.valueOfTotalStakedAmountInWeth
                  ? `${stakingInfo.valueOfTotalStakedAmountInWeth.toFixed(8, {
                      groupSeparator: ','
                    })} ${getNativeSymbol(chainId)}`
                  : '-'}
              </span>
            </li>
            {/* <li>Pool reward allocation <span className='mg-l-auto'>{poolSharePercentage ? `${poolSharePercentage.toSignificant(4)}%` : '-'}</span></li> */}
            <li>
              Emission rate{' '}
              <span className="mg-l-auto">
                {stakingInfo
                  ? stakingInfo.active
                    ? `${stakingInfo.poolRewardsPerBlock.toSignificant(3, { groupSeparator: ',' })} 
                ${govToken?.symbol} / Block`
                    : `0 ${govToken?.symbol} / Block`
                  : '-'}
              </span>
            </li>
            {isStaking && (
              <>
                <Break />
                <li>
                  Your Unlocked Rewards <span className="mg-l-auto">🔓</span>
                  {stakingInfo
                    ? stakingInfo.active
                      ? `${stakingInfo.unlockedEarnedAmount.toSignificant(4, { groupSeparator: ',' })} ${
                          govToken?.symbol
                        } / $${
                          govTokenPrice
                            ? stakingInfo.unlockedEarnedAmount.toSignificant(2, { groupSeparator: ',' })
                            : '0'
                        }`
                      : `0 ${govToken?.symbol}`
                    : '-'}
                </li>
                <li>
                  Your Locked Rewards <span className="mg-l-auto">🔒</span>
                  {stakingInfo
                    ? stakingInfo.active
                      ? `${stakingInfo.lockedEarnedAmount.toSignificant(4, { groupSeparator: ',' })} ${
                          govToken?.symbol
                        } / $${
                          govTokenPrice ? stakingInfo.lockedEarnedAmount.toSignificant(2, { groupSeparator: ',' }) : '0'
                        }`
                      : `0 ${govToken?.symbol}`
                    : '-'}
                </li>
                <Break />
                <li>
                  Your Total Rewards <span className="mg-l-auto">⚡</span>
                  {stakingInfo
                    ? stakingInfo.active
                      ? `${stakingInfo.earnedAmount.toSignificant(4, { groupSeparator: ',' })} ${govToken?.symbol} / $${
                          govTokenPrice ? stakingInfo.earnedAmount.toSignificant(2, { groupSeparator: ',' }) : '0'
                        }`
                      : `0 ${govToken?.symbol}`
                    : '-'}
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </>
  )
}
