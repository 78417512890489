import React, { useEffect, useState } from 'react'
import { AutoColumn } from '../../components/Column'
import { ButtonLight } from '../../components/Button'
import styled from 'styled-components'
import { useSingleStakingInfo, useLockTreatsInfo } from '../../state/stake/hooks'
import { SINGLE_STAKING_REWARDS_INFO, STAKING_REWARDS_INFO } from '../../constants/staking'
import { TYPE, NotAvailableChain } from '../../theme'
import { RowBetween } from '../../components/Row'
// import { Countdown } from './Countdown'
import Loader from '../../components/Loader'
import { useWeb3React } from '@web3-react/core'
// import { JSBI } from 'sdk'
// import { BIG_INT_ZERO } from '../../constants'
import { LightGreyCard } from '../../components/Card'
// import useTotalCombinedTVL from '../../utils/useTotalCombinedTVL'
import { useWalletModalToggle } from '../../state/application/hooks'
import useSingleStakingTokenAddresses from '../../hooks/useSingleStakingTokenAddresses'
import useGovernanceToken from '../../hooks/useGovernanceToken'
import StackItem from './StackItem'
import { ethers } from 'ethers'
import governance_token from '../../constants/abis/governance-token.json'
import { ChainId } from 'sdk'
import { useHistory } from 'react-router-dom'
import { Flex } from 'rebass'
import { getNativeSymbol } from 'utils'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

const PoolSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 10px;
  row-gap: 15px;
  width: 100%;
  justify-self: center;
`

const DataRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
flex-direction: column;
`};
`

export default function SingleFarm() {
  const { chainId, account, provider } = useWeb3React()

  const toggleWalletModal = useWalletModalToggle()
  const govToken = useGovernanceToken()
  // staking info for connected account
  const singleStakingTokenAddresses = useSingleStakingTokenAddresses()
  const lockTreatsInfo = useLockTreatsInfo()
  const stakingInfos = useSingleStakingInfo(undefined, singleStakingTokenAddresses)
  const [fetched, setFetched] = useState(false)

  /**
   * only show staking cards with balance
   * @todo only account for this if rewards are inactive
   */
  const stakingInfosWithBalance = stakingInfos?.filter(s => s.active)

  const unlocktreat = async () => {
    if (!chainId) return
    const addr = SINGLE_STAKING_REWARDS_INFO[chainId]
    console.log(addr[1].token.address)
    const getUnlock = new ethers.Contract(addr[1].token.address, governance_token.abi, provider?.getSigner())
    let tx
    try {
      tx = await getUnlock.unlock()
      await tx.wait()
      setFetched(!fetched)
    } catch (err) {
      console.log('complete')
    }
  }

  // toggle copy if rewards are inactive
  const stakingRewardsExist = Boolean(typeof chainId === 'number' && (STAKING_REWARDS_INFO[chainId]?.length ?? 0) > 0)

  // const TVLs = useTotalCombinedTVL(stakingInfos)
  return chainId !== ChainId.MAINNET && chainId !== ChainId.GÖRLI ? (
    <NotAvailableChain />
  ) : (
    <div>
      <div className="cardWrap">
        <h2 className="titleHead d-flex">Single Asset Staking (HOKK or TREATS)</h2>
        <div className="cardIn">
          <div className="cardContent">
            Deposit your {getNativeSymbol(chainId)} HOKK to receive $TREATS and {getNativeSymbol(chainId)}, once all
            $TREATS have been farmed you will continue to receive {getNativeSymbol(chainId)} for staking your HOKK.
          </div>
        </div>
      </div>
      <div className="cardWrap mt-5">
        <h2 className="titleHead d-flex">Participating pools</h2>
        {!account ? (
          <Flex justifyContent={'center'}>
            <ButtonLight onClick={toggleWalletModal}>Connect Wallet</ButtonLight>
          </Flex>
        ) : (
          <>
            <div className="cardIn">
              <div className="cardContent">
                <DataRow style={{ gap: '24px' }}>
                  {/* <PoolData> */}
                  <div className="cardWrap" style={{ width: '100%' }}>
                    <AutoColumn gap="sm">
                      <TYPE.body style={{ margin: 0 }}>Total Locked $TREATS</TYPE.body>
                      <TYPE.body fontSize={18} fontWeight={500}>
                        {lockTreatsInfo.totalLockedTreats &&
                          `${lockTreatsInfo.totalLockedTreats?.toSignificant(4, { groupSeparator: ',' })} ${
                            govToken?.symbol
                          }`}
                      </TYPE.body>
                    </AutoColumn>
                    {/* </PoolData> */}
                  </div>

                  {/* <PoolData> */}
                  <div className="cardWrap" style={{ width: '100%' }}>
                    <AutoColumn gap="sm">
                      <TYPE.body style={{ margin: 0 }}>Can Unlock $TREATS</TYPE.body>
                      <TYPE.body fontSize={18} className="d-flex" fontWeight={500}>
                        {lockTreatsInfo.canUnlockTreats &&
                          `${lockTreatsInfo.canUnlockTreats?.toSignificant(4, { groupSeparator: ',' })} ${
                            govToken?.symbol
                          }`}
                        <button className="btnMain mg-l-auto boxShdwNone" onClick={unlocktreat}>
                          Unlock
                        </button>
                      </TYPE.body>
                    </AutoColumn>
                    {/* </PoolData> */}
                  </div>
                </DataRow>
              </div>
            </div>
            {!account ? (
              <ButtonLight onClick={toggleWalletModal}>Connect Wallet</ButtonLight>
            ) : (
              <PoolSection>
                {stakingRewardsExist && stakingInfos?.length === 0 ? (
                  <Loader style={{ margin: 'auto' }} />
                ) : !stakingRewardsExist ? (
                  <LightGreyCard>No active pools</LightGreyCard>
                ) : stakingInfos?.length !== 0 && stakingInfosWithBalance.length === 0 ? (
                  <LightGreyCard>No active pools</LightGreyCard>
                ) : (
                  stakingInfosWithBalance?.map(stakingInfo => {
                    // need to sort by added liquidity here
                    return <StackItem key={stakingInfo.pid} stakingInfo={stakingInfo} isArchived={false}></StackItem>
                  })
                )}
              </PoolSection>
            )}
          </>
        )}
      </div>
    </div>
  )
}
