import { useMemo } from 'react'
import useGovernanceToken from './useGovernanceToken'
import useSingleAssetHokkToken from './useSingleAssetHokkToken'
import useTokenWethPrice from './useTokenWETHPrice'
// import getToken from '../utils/getToken'
import { useWeb3React } from '@web3-react/core'
import { ChainId, WETH } from 'sdk'

export default function useTokensWithWethPrices(): Record<string, any> {
  const { chainId } = useWeb3React()

  const weth = chainId && WETH[chainId] ? WETH[chainId] : undefined
  const hokk = useSingleAssetHokkToken()
  const govToken = useGovernanceToken()
  const govTokenWETHPrice = useTokenWethPrice(govToken)
  const hokkWETHPrice = useTokenWethPrice(hokk)

  return useMemo(() => {
    return {
      WETH: { token: weth, price: undefined },
      govToken: { token: govToken, price: govTokenWETHPrice },
      hokk: { token: hokk, price: hokkWETHPrice }
    }
  }, [weth, govToken, hokk, govTokenWETHPrice, hokkWETHPrice])
}
