import 'inter-ui'
import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { HashRouter } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'

import Blocklist from './components/Blocklist'
import App from './pages/App'
import store from './state'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import ApplicationUpdater from './state/application/updater'
import ListsUpdater from './state/lists/updater'
import MulticallUpdater from './state/multicall/updater'
import TransactionUpdater from './state/transactions/updater'
import UserUpdater from './state/user/updater'
import ThemeProvider, { FixedGlobalStyle, ThemedGlobalStyle } from './theme'

import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap';

import './assets/css/participatingpools.css'
import './assets/css/addliquidity.css'
import './assets/css/exchange.css'
import './assets/css/lottery.css'
import './assets/css/Menu.css'
import './assets/css/popup.css'
import './assets/css/treatsethliquiditymining.css'
import './assets/css/style.css'
import Web3Provider from 'components/Web3Provider'

if (!!window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false
}

function Updaters() {
  return (
    <>
      <ListsUpdater />
      <UserUpdater />
      <ApplicationUpdater />
      <TransactionUpdater />
      <MulticallUpdater />
    </>
  )
}

ReactDOM.render(
  <StrictMode>
    <FixedGlobalStyle />
    <Provider store={store}>
      <HashRouter>
        <Web3Provider>
          <Blocklist>
            <Updaters />
            <ThemeProvider>
              <ThemedGlobalStyle />
              <App />
              <Toaster
                position='top-right'
                reverseOrder={false}
              />
            </ThemeProvider>
          </Blocklist>
        </Web3Provider>
      </HashRouter>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
)

serviceWorkerRegistration.unregister()
