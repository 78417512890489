// import { ChainId, TokenAmount } from 'sdk'
// import { ChainId } from '@mdex/heco-sdk'

import React from 'react'
import { Text } from 'rebass'
import { NavLink } from 'react-router-dom'
import { darken } from 'polished'
import styled from 'styled-components'

import { useWeb3React } from '@web3-react/core'
import NetworkSelector from './NetworkSelector'

import { RowFixed } from '../Row'
import Web3Status from '../Web3Status'
import Menu, { StyledMenuButton } from '../Menu'
import logo from '../../assets/images/logo.png'
import { ReactComponent as ListIcon } from '../../assets/images/list.svg'
import { MEDIA_WIDTHS } from 'theme'
import { useWindowSize } from 'hooks/useWindowSize'
import { useOpenModal } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/actions'

const HeaderFrame = styled.div`
  position: fixed;
  width: 100%;
  top: 0px;
  display: grid;
  grid-template-columns: 1fr 420px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 2rem;
  z-index: 98;
  background: ${({ theme }) => theme.bg1};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
    position: inherit;
    padding: 1rem;
  `}
`

const HeaderRow = styled(RowFixed)`
  padding: 0px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    justify-content: space-between;
  `};
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
  gap: 12px;

  ${({ theme }) => theme.mediaWidth.upToSmall`  
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    justify-self: center;
    padding: 1rem;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 98;
    height: 72px;
    border-radius: 12px 12px 0px 0px;
    background-color: rgb(33, 36, 41);
  `}
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
   flex-direction: row-reverse;
    align-items: center;
  `};
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;

  :focus {
    border: 1px solid blue;
  }
`

const Logo = styled.img`
  height: 42px;
`

const activeClassName = 'ACTIVE'

const HeaderLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: fit-content;
  margin: 0;
  font-weight: 500;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    align-items: center;
  `};
`

/*
const NETWORK_LABELS: { [chainId in ChainId]?: string } = {
  [ChainId.MAINNET]: ''
}
*/
export default function Header() {
  const { account } = useWeb3React()
  const { width } = useWindowSize()
  const isMobile = width && width < MEDIA_WIDTHS.upToSmall
  const openSidebar = useOpenModal(ApplicationModal.SIDEBAR)

  return (
    <HeaderFrame>
      <HeaderRow>
        <HeaderLink to="/">
          <Logo src={logo} />
        </HeaderLink>
        {isMobile ? (
          <StyledMenuButton onClick={openSidebar}>
            <ListIcon />
          </StyledMenuButton>
        ) : null}
      </HeaderRow>
      <HeaderControls>
        <HeaderElement>
          <NetworkSelector />
        </HeaderElement>
        <HeaderElement>
          <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
            <Web3Status />
          </AccountElement>
        </HeaderElement>
        <HeaderElementWrap>
          <Menu />
        </HeaderElementWrap>
      </HeaderControls>
    </HeaderFrame>
  )
}
