import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { ethers } from 'ethers'
import { RouteComponentProps } from 'react-router-dom'
import { BridgeChain, BridgeToken, HOKK } from 'constants/index'
import { ButtonPrimary } from '../../components/Button'
import { Text } from 'rebass'
import { Link } from 'react-router-dom'
import { ArrowDown } from 'react-feather'
import { useWalletModalToggle } from 'state/application/hooks'
import { useWeb3React } from '@web3-react/core'
import { calculateGasMargin, getBridgeContract, getBridgeObject } from 'utils'
import { TransactionResponse } from '@ethersproject/abstract-provider'
import { BigNumber } from '@ethersproject/bignumber'
import { useIsTransactionPending, useTransactionAdder } from 'state/transactions/hooks'
import { BridgeCallbackError } from 'components/Bridge/styled'
import ChainSelector from 'components/Bridge/ChainSelector'
import TokenSelector from 'components/Bridge/TokenSelector'
import AmountSelector from 'components/Bridge/AmountSelector'
import AddressSelector from 'components/Bridge/AddressSelector'
import axios from 'axios'
import { ExternalLink, InnerWrapper } from 'theme'
import { switchChain } from 'utils/switchChain'
import { ChainId } from 'sdk'
import { BodyWrapper } from 'pages/AppBody'
import { useTokenBalance } from 'state/wallet/hooks'
import { isMobile } from 'react-device-detect'

const Wrapper = styled(BodyWrapper)`
  padding: 2rem;
  min-height: calc(100vh - 120px);
  @media (max-width: 768px) {
    padding: 1rem;
  }
`
const Title = styled(Text)`
  font-weight: 500;
  font-size: 24px;
`
const ChainChanger = styled.div`
  cursor: pointer;
  position: absolute;
  top: 120px !important;
  left: calc(50% - 18px);
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3050d1;
  border-radius: 50%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  top: 97px !important;
`};
`
const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 24px 0px;
  gap: 24px;
`
const ButtonUnlock = styled(ButtonPrimary)`
  width: 160px;
  filter: drop-shadow(0px 12px 25px rgba(28, 64, 194, 0.24));
  padding: 13px 22px;
  border-radius: 24px;
`
const ButtonHistory = styled(ButtonPrimary)`
  width: 160px;
  filter: drop-shadow(0px 12px 25px rgba(28, 64, 194, 0.24));
  padding: 13px 22px;
  border-radius: 24px;
  margin: 24px 0px;
`
const BridgeGasText = styled(Text)`
  padding-top: 12px;
  text-align: center;
`

export default function Bridge({ history }: RouteComponentProps) {
  const { account, chainId, provider, connector } = useWeb3React()
  const [chainA, setChainA] = useState<BridgeChain>(BridgeChain.BSC)
  const [chainB, setChainB] = useState<BridgeChain>(BridgeChain.HECO)
  const [token, setToken] = useState<BridgeToken>(BridgeToken.HOKK)
  const [amount, setAmount] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [bridge, setBridge] = useState<any>({})
  const balance = useTokenBalance(account ?? undefined, HOKK[chainId ?? 1])
  const [checkChain, setCheckChain] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const isPending = useIsTransactionPending(txHash)
  const [gas, setGas] = useState<number>(0)

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  useEffect(() => {
    async function fetchBrigeObject() {
      let obj = await getBridgeObject()
      if (obj) {
        setBridge(obj)
      }
    }
    fetchBrigeObject()
  }, [])

  useEffect(() => {
    if (account) {
      setAddress(account)
    }
  }, [account])

  useEffect(() => {
    if (!account) {
      setCheckChain(false)
    } else {
      if (chainA === BridgeChain.ETH) {
        if (chainId === 1 || chainId === 5) {
          // Ropsten
          setCheckChain(true)
        } else {
          setCheckChain(false)
        }
      } else if (chainA === BridgeChain.BSC) {
        if (chainId === 56 || chainId === 97) {
          // BSC Testnet
          setCheckChain(true)
        } else {
          setCheckChain(false)
        }
      } else if (chainA === BridgeChain.HECO) {
        if (chainId === 128 || chainId === 256) {
          // HECO Testnet
          setCheckChain(true)
        } else {
          setCheckChain(false)
        }
      }
    }
  }, [account, chainId, chainA])

  useEffect(() => {
    async function fetchGas() {
      if (!account || !bridge || !address || !amount) {
        return
      }

      const destination = getDestination()

      const { data } = await axios.get<any>(bridge['url'] + '/validator/gas/', {
        params: {
          endChain: BridgeChain[chainB],
          startChain: BridgeChain[chainA],
          sender: account,
          recipient: address,
          destination: destination,
          amount: ethers.utils.parseUnits(amount, 18).toString()
        }
      })

      if (data['status'] !== 'success') {
        setGas(0)
        return
      }

      setGas(data['totalCoinCost'])
    }

    fetchGas()
  }, [account, bridge, chainA, chainB, address, amount])

  const handleChainA = useCallback(
    (oldChain, newChain) => {
      console.log(oldChain, newChain)

      // If set same chain, exchange
      if (chainB === newChain) {
        setChainB(oldChain)
      }

      setChainA(newChain)
      setErrorMessage('')
    },
    [chainA, chainB]
  )

  const handleChainB = useCallback(
    (oldChain, newChain) => {
      console.log(oldChain, newChain)

      // If set same chain, exchange
      if (chainA === newChain) {
        setChainA(oldChain)
      }

      setChainB(newChain)
      setErrorMessage('')
    },
    [chainA, chainB]
  )

  const handleToken = useCallback(
    _token => {
      setToken(_token)
    },
    [setToken]
  )

  const handleChangeChain = () => {
    let _chainA: BridgeChain = chainA
    let _chainB: BridgeChain = chainB

    setChainA(_chainB)
    setChainB(_chainA)
    setErrorMessage('')
  }

  const handleAmount = useCallback(
    _amount => {
      setAmount(_amount)
    },
    [setAmount]
  )

  const handleAddress = useCallback(
    _address => {
      setAddress(_address)
    },
    [setAddress]
  )

  const unlockWallet = async () => {
    if (!account || !provider) {
      toggleWalletModal()
    } else {
      const targetChain =
        chainA === BridgeChain.ETH ? 1 : chainA === BridgeChain.BSC ? 56 : chainA === BridgeChain.HECO ? 128 : 1

      if (chainId !== targetChain) {
        switchChain(connector, targetChain)
      }
    }
  }

  const getDestination = () => {
    let destination = undefined

    if (chainB === BridgeChain.ETH) {
      if (chainId === ChainId.MAINNET || chainId === ChainId.BSC_MAINNET || chainId === ChainId.HECO_MAINNET) {
        destination = HOKK[ChainId.MAINNET].address
      } else {
        destination = HOKK[ChainId.GÖRLI].address
      }
    } else if (chainB === BridgeChain.BSC) {
      if (chainId === ChainId.MAINNET || chainId === ChainId.BSC_MAINNET || chainId === ChainId.HECO_MAINNET) {
        destination = HOKK[ChainId.BSC_MAINNET].address
      } else {
        destination = HOKK[ChainId.BSC_TESTNET].address
      }
    } else if (chainB === BridgeChain.HECO) {
      if (chainId === ChainId.MAINNET || chainId === ChainId.BSC_MAINNET || chainId === ChainId.HECO_MAINNET) {
        destination = HOKK[ChainId.HECO_MAINNET].address
      } else {
        destination = HOKK[ChainId.HECO_TESTNET].address
      }
    }

    return destination
  }

  // tx sending
  const addTransaction = useTransactionAdder()
  async function approve() {
    if (!chainId || !provider || !account) return

    if (!amount) {
      setErrorMessage('You need input amount.')
      return
    }

    if (!bridge) {
      setErrorMessage('Bridge not ready yet.')
      return
    }

    const contract = getBridgeContract(chainId, provider, account)
    const destination = getDestination()
    if (!destination) {
      setErrorMessage('Bridge not matched.')
      return
    }

    let estimate,
      method: (...args: any) => Promise<TransactionResponse>,
      args: Array<string | string[] | number>,
      value: BigNumber | null

    estimate = contract.estimateGas.swapAcrossChain
    method = contract.swapAcrossChain
    args = [
      chainB === BridgeChain.BSC
        ? 'BSC'
        : chainB === BridgeChain.ETH
          ? 'ETH'
          : chainB === BridgeChain.HECO
            ? 'HECO'
            : '', // endChain
      bridge['moniker'], // preferredNode,
      ethers.utils.parseUnits(amount, 18).toString(), // amount
      address, // recipient
      destination
    ]
    value = BigNumber.from(gas)

    setAttemptingTxn(true)
    await estimate(...args, value ? { value } : {})
      .then(estimatedGasLimit =>
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit)
        }).then(response => {
          setAttemptingTxn(false)
          setAmount('')

          addTransaction(response, {
            summary: 'Swap has been successed.'
          })
          setTxHash(response.hash)
          setErrorMessage('')
        })
      )
      .catch(error => {
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          if (error.data) {
            setErrorMessage(error.data.message)
          } else {
            setErrorMessage(error.message)
          }
        } else {
          setErrorMessage('User rejected transaction.')
        }
      })
  }

  const getCoin = () => {
    if (chainId === ChainId.MAINNET || chainId === ChainId.GÖRLI) {
      return 'ETH'
    }

    if (chainId === ChainId.BSC_MAINNET || chainId === ChainId.BSC_TESTNET) {
      return 'BNB'
    }

    if (chainId === ChainId.HECO_MAINNET || chainId === ChainId.HECO_TESTNET) {
      return 'HT'
    }

    return ''
  }

  return (
    <>
      <Wrapper>
        <Title>Cross-Chain Token Bridge</Title>

        <InnerWrapper>
          <Title textAlign="center">Chain</Title>

          <ChainSelector chainId={chainA} onChange={handleChainA} />

          <ChainChanger onClick={handleChangeChain}>
            <ArrowDown fontSize={18} />
          </ChainChanger>

          <ChainSelector chainId={chainB} onChange={handleChainB} />

          <Title textAlign="center">Token</Title>

          <TokenSelector token={token} onChange={handleToken} />

          {checkChain ? (
            <>
              <AddressSelector address={address} onChange={handleAddress} />

              <AmountSelector amount={amount} balance={balance} onChange={handleAmount} />

              <BridgeGasText>
                Transaction & gas costs for the bridge usage will be {ethers.utils.formatUnits(gas).substr(0, 5)}{' '}
                {getCoin()}
              </BridgeGasText>
            </>
          ) : null}

          {errorMessage ? <BridgeCallbackError error={errorMessage} /> : null}

          <FooterWrapper>
            {checkChain ? (
              <>
                <ButtonHistory onClick={approve} disabled={isPending || attemptingTxn}>
                  {isPending ? 'Pending' : 'Confirm'}
                </ButtonHistory>
                <ButtonHistory as={Link} to={'/bridge_history'}>
                  History
                </ButtonHistory>
              </>
            ) : (
              <ButtonUnlock onClick={unlockWallet}>Unlock Wallet</ButtonUnlock>
            )}
          </FooterWrapper>

          {
            isMobile ?
              <Text textAlign="center" marginBottom="18px">
                We recommend using the bridge on a desktop/laptop for the best user experience
              </Text> : null
          }

          <Text textAlign="center">
            For HOKK Bridge Support Please Reach Out To: <ExternalLink href="https://t.me/joinchat/9iz8X4hu1ypmYjhh">https://t.me/joinchat/9iz8X4hu1ypmYjhh</ExternalLink>
          </Text>

        </InnerWrapper>
      </Wrapper>
    </>
  )
}
