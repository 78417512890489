import {
  ChainId,
  Token
} from 'sdk'
import getPairTokensWithDefaults from '../utils/getPairTokensWithDefaults'
// import { ZERO_ADDRESS } from '../constants/index'

export interface StakingRewardsInfo {
  pid: number
  tokens: [Token, Token]
  active: boolean
}

export interface SingleStakingRewardsInfo {
  pid: number
  token: Token
  active: boolean
}

export const STAKING_REWARDS_INFO: {
  [chainId: number]: StakingRewardsInfo[]
} = {
  [ChainId.MAINNET]: [
    // {
    //   pid: 1,
    //   tokens: getPairTokensWithDefaults(ChainId.MAINNET, 'TREATS/USDC'),
    //   active: true
    // },
    {
      pid: 1,
      tokens: getPairTokensWithDefaults(ChainId.MAINNET, 'HOKK/WETH'),
      active: true
    },
    {
      pid: 2,
      tokens: getPairTokensWithDefaults(ChainId.MAINNET, 'TREATS/WETH'),
      active: true
    },
    // {
    //   pid: 3,
    //   tokens: getPairTokensWithDefaults(ChainId.MAINNET, 'BUSD/VIPER'),
    //   active: true
    // },
  ],
  [ChainId.GÖRLI]: [
    {
      pid: 1,
      tokens: getPairTokensWithDefaults(ChainId.GÖRLI, 'HOKK/WETH'),
      active: true
    },
    {
      pid: 2,
      tokens: getPairTokensWithDefaults(ChainId.GÖRLI, 'TREATS/WETH'),
      active: true
    }
  ]
}

export const SINGLE_STAKING_REWARDS_INFO: {
  [chainId: number]: SingleStakingRewardsInfo[]
} = {
  [ChainId.MAINNET]: [
    {
      pid: 0,
      token: new Token(ChainId.MAINNET, "0xe87e15B9c7d989474Cb6d8c56b3DB4eFAD5b21E8", 18, 'HOKK', 'HOKK'),
      active: true
    },
    {
      pid: 3,
      token: new Token(ChainId.MAINNET, "0xb4e1bba882e1058dC9088c69F26f832394caBc77", 18, 'TREATS', 'TREATS'),
      active: true
    },
  ],
  [ChainId.GÖRLI]: [
    {
      pid: 0,
      token: new Token(ChainId.GÖRLI, "0x71f4b105249dB65DecF7736e5362E6857D7f05AA", 18, 'HOKK', 'HOKK'),
      active: true
    },
    {
      pid: 3,
      token: new Token(ChainId.GÖRLI, "0xEf73C086aed13232CeC3e24888c4b24547948FC6", 18, 'TREATS', 'TREATS'),
      active: true
    },
  ]
}
