import { Input } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  background: ${({ theme }) => theme.bg9};
  width: 100%;
  padding: 12px 24px;
  margin: 0px;
  border-radius: 42px;
  font-size: 20px;
  
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};

`

const AddressInput = styled(Input)`
  width: 100%;

  & input {
    font-size: 18px;
    font-family: "Inter var", sans-serif;
  }

`

export default function AddressSelector({
  address,
  onChange
}: {
  address: string,
  onChange: (address_: string) => void
}) {

  const changeAddress = (e: any) => {
    var _address = e.target.value;
    onChange(_address);

    address = _address;
  }

  return (
    <Wrapper style={{ marginTop: '24px' }}>

      <AddressInput style={{ color: 'white' }} value={address} onChange={changeAddress} placeholder="Enter recipient address: 0x...." disableUnderline={true} />

    </Wrapper>
  )

}
